import { Component, OnInit, Input, Output, EventEmitter  } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { String as IpsString } from "typescript-string-operations";
import { Observable } from "rxjs";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import cloneDeep from "lodash-es/cloneDeep";

import { MarketModel, MarketService } from "../../market/index";
import { MarketChangedEventModel } from "../model/MarketChangedEventModel";
import { ProfileQuestionModel } from "../model/ProfileQuestionModel";
import { MarketType } from "../type/MarketType";

@Component({
  selector: "app-market-typeahead-search",
  templateUrl: "./market-typeahead-search.component.html",
  styleUrls: ["./market-typeahead-search.component.scss"]
})
export class MarketTypeaheadSearchComponent implements OnInit {
    @Input() market: FormGroup;
    @Input() marketIndex: number;
    @Input() marketSource: Observable<any>;
    @Input() pendingLocationWarning: string;
    @Input() showInUseFeatures = false; // Set to true to show already in use Features. Survey uses this.
    @Input() disableByFeature = true; //When this is true features will be disabled in the modal popup, when false feature subgroups will be disabled
    @Input() isSingleSelect = false;
    @Input() featuresInAudience = [] as MarketModel[];
    @Input() featuresInQuestion = [] as ProfileQuestionModel[];
    @Input() fixtureGroupsInQuestion = [] as ProfileQuestionModel[];
    @Input() fixturesInQuestion = [] as ProfileQuestionModel[];
    @Output() marketChanged = new EventEmitter<MarketChangedEventModel>();
    @Output() marketDeleted = new EventEmitter<any>();
    @Output() removeMarkets = new EventEmitter<any>();
    @Output() addNewMarkets = new EventEmitter<any>();

    public LinkedProfileMode = false; // True when showing Linked location feature / fixture group otherwise showing location/location feature/location search
    public marketPrefix: string;
    public existingMarkets: any= [];

    constructor(private marketService: MarketService) { }

    ngOnInit() {
        const market = this.market.value as MarketModel;
        if (market.LinkedProfileMode !== null && market.LinkedProfileMode !== undefined) {
            this.LinkedProfileMode = market.LinkedProfileMode;
        } else {
            this.LinkedProfileMode = market.TargetMarketType === "LocationGroup";
        }
        this.marketPrefix = this.getMarketPrefix(this.market.value);
    }

    public typeaheadOnSelect(e: TypeaheadMatch, marketCtrl: FormGroup): void {
        let item = <MarketModel>e.item;
        let marketObj = marketCtrl.value;
        this.marketService.updateMarketCtrl(item, marketCtrl);
        this.marketPrefix = this.getMarketPrefix(item);
        if (marketObj.TargetMarketType !== item.TargetMarketType || marketObj.TargetMarketId !== item.TargetMarketId) {
            //raise event to parent to update calculation
            this.marketChanged.emit({previous: marketObj, current: item});
        }

    }

    changeMarketPrefix() {
        this.marketPrefix = "";
    }

    public searchMarket(market: FormGroup, requestedMarketType: MarketType): void {
        this.marketPrefix = "";
        const previous: any = cloneDeep(market.value);

        if (requestedMarketType === "AllExceptClosed") {
            let newMarket = market.value;
            this.marketService.updateMarketFromLocationAll(newMarket);
            this.marketService.updateMarketCtrl(newMarket, market);
            //raise event to parent to update calculation
            this.marketChanged.emit({ previous: previous, current: newMarket });
        } else {

            this.marketService.showMarketSelectionModal(market.value, this.featuresInAudience, this.featuresInQuestion, this.showInUseFeatures, this.disableByFeature, requestedMarketType, this.fixtureGroupsInQuestion, this.fixturesInQuestion, this.isSingleSelect)
                .then((newMarket) => {
                    if (newMarket instanceof Array) {
                        let marketsToRemove = newMarket.filter(x => x.Disabled === true);
                        if (marketsToRemove.length > 0) {
                            this.removeSelctedMarkets(marketsToRemove);
                        }
                        let markets = newMarket.filter(x => x.Disabled === false || x.Disabled === undefined);
                        if (markets.length > 0) {
                            this.addSelectedMarkets(markets);
                        }
                        if(this.isSingleSelect){
                            this.marketPrefix = this.getMarketPrefix(newMarket[0]);
                            this.marketService.updateMarketCtrl(newMarket[0], market);
                            //raise event to parent to update calculation
                            this.marketChanged.emit({ previous: previous, current: newMarket[0] });
                        }
                    }else {
                    this.marketPrefix = this.getMarketPrefix(newMarket);
                    this.marketService.updateMarketCtrl(newMarket, market);
                    //raise event to parent to update calculation
                    this.marketChanged.emit({ previous: previous, current: newMarket });
                    }
                }).catch(() => {
                    // do nothing on close
                });
        }
    }

    public addSelectedMarkets (newMarkets: any) {
        //raise event to parent to add new market
        this.addNewMarkets.emit({markets: newMarkets });
    }

    public removeSelctedMarkets (marketsToRemove: any) {
        //raise event to parent to remove market
        this.removeMarkets.emit({markets: marketsToRemove });
    }

    public hasPendingLocations(market: FormGroup): boolean {
        return market.valid && market.value.PendingLocationCount > 0;
    }

    public getPendingLocationWarningString(market: MarketModel): string {
        return IpsString.Format(this.pendingLocationWarning, market.PendingLocationCount.toString());
    }

    public removeMarket(marketIndex: number, market: FormGroup): void {
        //raise event to parent to remove market
        this.marketDeleted.emit({ index: marketIndex, market: market });
    }

    public getMarketPrefix(marketFormGroup: MarketModel): string {
        const marketType = marketFormGroup.TargetMarketType as MarketType;
        return this.marketService.getMarketPrefix(marketType);
    }
}
