import { from as observableFrom, of as observableOf, timer as observableTimer } from "rxjs";
import { map, debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { EventEmitter } from "@angular/core";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { MarketService } from "../../market";
import { PromotionMessageService } from "../service/promotion-message.service";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
var PromotionHelperService = /** @class */ (function () {
    function PromotionHelperService(activeProfileService, marketService, fb, promotionMessageService) {
        this.activeProfileService = activeProfileService;
        this.marketService = marketService;
        this.fb = fb;
        this.promotionMessageService = promotionMessageService;
        this.msgHtmlName = "MessageHtmlName";
        this.tempPatternGroupId = 1;
        this.msgIndexer = 0;
    }
    PromotionHelperService.prototype.createForm = function (parent, messageNameChanged, useMessagePattern, msgPatternType) {
        //clear data when toggle pattern on/off
        if (parent.get("messageForm")) {
            parent.removeControl("messageForm");
            this.tempPatternGroupId = 1;
        }
        this.parent = parent;
        this.messageNameChanged = messageNameChanged;
        var messageForm;
        if (msgPatternType === "priority") {
            messageForm = this.fb.group({
                PatternGroups: this.fb.array([this.newPriorityFillGroup(useMessagePattern, true)])
            });
        }
        else {
            messageForm = this.fb.group({
                PatternGroups: this.fb.array([this.newPatternGroup(useMessagePattern, true)])
            });
        }
        parent.addControl("messageForm", messageForm);
    };
    /**
     * returns unique message count accross patternGroups
     * @param patternGroupsModel
     */
    PromotionHelperService.prototype.initializeForm = function (patternGroupsModel, isClone) {
        var _this = this;
        var msgCount = 0;
        var _loop_1 = function (i) {
            var patternGroupModel = patternGroupsModel[i];
            if (isClone) {
                patternGroupModel.Id = 0;
                patternGroupModel.MarketGroupId = 0;
                patternGroupModel.Markets.forEach(function (m) {
                    m.Id = 0;
                });
            }
            var patternGroups = this_1.PatternGroups;
            var patternGrpCtrl = patternGroups.at(i);
            var allIds = patternGroupModel.MessagePatterns.filter(function (p) { return p.PromotionMessageId !== undefined; }).map(function (p) { return p.TempMessageId; });
            msgCount = msgCount + new Set(allIds).size;
            if (!patternGrpCtrl) {
                patternGrpCtrl = this_1.createPatternGroupFormGroup(patternGroupModel);
                patternGroups.push(patternGrpCtrl);
            }
            else {
                patternGrpCtrl.patchValue({
                    Id: patternGroupModel.Id,
                    PromotionId: patternGroupModel.PromotionId,
                    Name: patternGroupModel.Name,
                    MarketGroupId: patternGroupModel.MarketGroupId,
                    BusinessIdentity: patternGroupModel.BusinessIdentity,
                    PatternGroupType: patternGroupModel.PatternGroupType
                });
            }
            //init message patterns
            var patternArray = patternGrpCtrl.get("MessagePatterns");
            while (patternArray.length > 0) {
                patternArray.removeAt(0);
            }
            //set IsEmpty to ture if promomtionMessagId of MessagePatern == null
            patternGroupModel.MessagePatterns.map(function (msgPattern) {
                // empty messages have undefined id, leave those alone when cloning
                if (isClone) {
                    msgPattern.Id = 0;
                    msgPattern.PromotionMessagePatternGroupId = 0;
                    if (msgPattern.PromotionMessageId !== undefined) {
                        msgPattern.PromotionMessageId = 0;
                    }
                }
                if (msgPattern.PromotionMessageId === undefined) {
                    msgPattern.IsEmpty = true;
                    msgPattern.MessageName = _this.emptyPositionMessageName;
                }
                else {
                    msgPattern.IsEmpty = false;
                }
                if (patternGroupModel.PatternGroupType === "PriorityMessage") {
                    patternArray.push(_this.createPriorityFormGroup(patternGrpCtrl, msgPattern));
                }
                else {
                    patternArray.push(_this.createPatternFormGroup(patternGrpCtrl, msgPattern));
                }
            });
        };
        var this_1 = this;
        for (var i = 0; i < patternGroupsModel.length; i++) {
            _loop_1(i);
        }
        return msgCount;
    };
    PromotionHelperService.prototype.createPatternGroupFormGroup = function (patternGroup) {
        var patternGroupCtrl = this.fb.group({
            Id: this.fb.control(patternGroup.Id),
            PromotionId: this.fb.control(patternGroup.PromotionId),
            Name: this.fb.control(patternGroup.Name),
            Markets: this.fb.array([]),
            MarketGroupId: this.fb.control(patternGroup.MarketGroupId),
            BusinessIdentity: this.fb.control(this.activeProfileService.businessIdentity),
            PatternGroupType: this.fb.control(patternGroup.PatternGroupType),
            TempPatternGroupId: this.tempPatternGroupId++
        });
        patternGroupCtrl.addControl("MessagePatterns", this.fb.array([]));
        return patternGroupCtrl;
    };
    PromotionHelperService.prototype.createPatternFormGroup = function (patternGroup, pattern) {
        var inputName = pattern.IsEmpty ? "" : this.msgHtmlName + this.msgIndexer++;
        var ctrl = new FormGroup({
            Id: this.fb.control(pattern.Id),
            MessageName: this.fb.control({ value: pattern.MessageName, disabled: pattern.IsEmpty }, [Validators.required, Validators.maxLength(350)], [this.checkMessageName(patternGroup)]),
            PromotionMessageId: this.fb.control(pattern.PromotionMessageId),
            PromotionMessagePatternGroupId: this.fb.control(pattern.PromotionMessagePatternGroupId),
            BusinessIdentity: this.fb.control(pattern.BusinessIdentity),
            IsEmpty: this.fb.control(pattern.IsEmpty),
            TempMessageId: this.fb.control(pattern.TempMessageId),
            Ordinal: this.fb.control(pattern.Ordinal),
            InputName: this.fb.control(inputName),
            IsCdfEnabled: this.fb.control(pattern.IsCdfEnabled || false)
        });
        this.addMsgNameChangeListener(patternGroup, ctrl);
        return ctrl;
    };
    PromotionHelperService.prototype.createPriorityFormGroup = function (patternGroup, pattern) {
        var inputName = pattern.IsEmpty ? "" : this.msgHtmlName + this.msgIndexer++;
        var marketCtrl = this.marketService.newMarketFormGroup(1);
        var ctrl = new FormGroup({
            Id: this.fb.control(pattern.Id),
            MessageName: this.fb.control({ value: pattern.MessageName, disabled: pattern.IsEmpty }, [Validators.required, Validators.maxLength(350)], [this.checkMessageName(patternGroup)]),
            PromotionMessageId: this.fb.control(pattern.PromotionMessageId),
            PromotionMessagePatternGroupId: this.fb.control(pattern.PromotionMessagePatternGroupId),
            BusinessIdentity: this.fb.control(pattern.BusinessIdentity),
            IsEmpty: this.fb.control(pattern.IsEmpty),
            TempMessageId: this.fb.control(pattern.TempMessageId),
            Ordinal: this.fb.control(pattern.Ordinal),
            InputName: this.fb.control(inputName),
            IsCdfEnabled: this.fb.control(pattern.IsCdfEnabled || false),
            MarketGroupId: this.fb.control(pattern.MarketGroupId),
            Markets: this.fb.array(pattern.Markets || marketCtrl)
        });
        this.addMsgNameChangeListener(patternGroup, ctrl);
        return ctrl;
    };
    PromotionHelperService.prototype.newPatternFormGroup = function (patternGroup, isEmptyPosition, isFirst) {
        if (isEmptyPosition === void 0) { isEmptyPosition = false; }
        if (isFirst === void 0) { isFirst = false; }
        var msgName = isEmptyPosition ? this.emptyPositionMessageName : isFirst ? this.parent.value.Name : "";
        var msgId = isEmptyPosition ? undefined : 0;
        var tempMsgId = isEmptyPosition ? undefined : this.tempMessageId++;
        var patternMsg = {
            Id: 0,
            MessageName: msgName,
            BusinessIdentity: this.activeProfileService.businessIdentity,
            PromotionMessageId: msgId,
            PromotionMessagePatternGroupId: 0,
            Ordinal: this.getNextPatternOrdinal(patternGroup),
            TempMessageId: tempMsgId,
            IsEmpty: isEmptyPosition,
            IsCdfEnabled: !isEmptyPosition
        };
        return this.createPatternFormGroup(patternGroup, patternMsg);
    };
    PromotionHelperService.prototype.newPriorityFillGroup = function (useMessagePattern, isFirst) {
        if (isFirst === void 0) { isFirst = false; }
        var marketCtrl = this.marketService.newMarketFormGroup(1);
        var patternType = "PriorityMessage";
        var patternGroup = this.fb.group({
            Id: this.fb.control(0),
            PromotionId: this.fb.control(0),
            Name: this.fb.control(""),
            Markets: this.fb.control([]),
            MarketGroupId: this.fb.control(0),
            BusinessIdentity: this.fb.control(this.activeProfileService.businessIdentity),
            PatternGroupType: this.fb.control(patternType),
            TempPatternGroupId: this.tempPatternGroupId++,
            IsCdfEnabled: true
        });
        patternGroup.addControl("MessagePatterns", this.fb.array([this.newPriorityFillFormGroup(patternGroup, isFirst)]));
        return patternGroup;
    };
    PromotionHelperService.prototype.newPriorityFillFormGroup = function (patternGroup, isFirst) {
        if (isFirst === void 0) { isFirst = false; }
        var msgName = isFirst ? this.parent.value.Name : "";
        var msgId = 0;
        var tempMsgId = this.tempMessageId++;
        //let marketCtrl = ;
        var patternMsg = {
            Id: 0,
            MessageName: msgName,
            BusinessIdentity: this.activeProfileService.businessIdentity,
            PromotionMessageId: msgId,
            PromotionMessagePatternGroupId: 0,
            Ordinal: this.getNextPatternOrdinal(patternGroup),
            TempMessageId: tempMsgId,
            IsCdfEnabled: true,
            Markets: []
        };
        return this.createPriorityFormGroup(patternGroup, patternMsg);
    };
    PromotionHelperService.prototype.newPatternGroup = function (useMessagePattern, isFirst) {
        if (isFirst === void 0) { isFirst = false; }
        var marketCtrl = this.marketService.newMarketFormGroup(1);
        var patternType = useMessagePattern ? "MultiMessage" : "SingleMessage";
        var patternGroup = this.fb.group({
            Id: this.fb.control(0),
            PromotionId: this.fb.control(0),
            Name: this.fb.control(""),
            Markets: this.fb.array([marketCtrl]),
            MarketGroupId: this.fb.control(0),
            BusinessIdentity: this.fb.control(this.activeProfileService.businessIdentity),
            PatternGroupType: this.fb.control(patternType),
            TempPatternGroupId: this.tempPatternGroupId++
        });
        patternGroup.addControl("MessagePatterns", this.fb.array([this.newPatternFormGroup(patternGroup, false, isFirst)]));
        return patternGroup;
    };
    PromotionHelperService.prototype.addMsgNameChangeListener = function (patternGroup, patternCtrl) {
        var _this = this;
        var messageName = patternCtrl.get("MessageName");
        messageName.valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(function (msg) {
            //when change message name after clone, need to mark the message name control touch for validation
            patternCtrl.get("MessageName").markAsTouched();
            var msgPatterns = _this.MessagePatterns(patternGroup);
            var pattern = patternCtrl.value;
            msgPatterns.controls.forEach(function (ptn) {
                if (ptn.value.TempMessageId === pattern.TempMessageId && ptn.value.MessageName !== msg) {
                    ptn.patchValue({
                        MessageName: msg
                    });
                }
            });
            var tempId = patternCtrl.get("TempMessageId").value;
            _this.messageNameChanged.emit({ MessageName: msg, TempMessageId: tempId });
        });
        //Add instant value change check for removing returns
        messageName.valueChanges.pipe(distinctUntilChanged()).subscribe(function (value) {
            _this.DisableReturn(messageName, value);
        });
    };
    PromotionHelperService.prototype.getMessageSuggestions = function () {
        var suggestions = [];
        var patternGroups = this.PatternGroups.value;
        if (patternGroups[0].PatternGroupType === "MultiMessage") {
            suggestions = patternGroups.reduce(function (accumlator, current) {
                return accumlator.concat(current.MessagePatterns.filter(function (pattern) { return !pattern.IsEmpty; }).map(function (msg) { return msg.MessageName; }));
            }, []);
        }
        if (patternGroups[0].PatternGroupType === "SingleMessage") {
            suggestions = patternGroups.map(function (msgPattern) { return msgPattern.MessagePatterns.map(function (msgName) { return msgName.MessageName; }); });
        }
        return suggestions.filter(function (value, index, self) {
            return self.indexOf(value) === index; // return unique name
        });
    };
    PromotionHelperService.prototype.getNextPatternOrdinal = function (patternGroup) {
        if (!this.parent.controls.messageForm || !this.PatternGroups) {
            return 1;
        }
        var patterns = patternGroup.value.MessagePatterns;
        if (!patterns || patterns.length <= 0) {
            return 1;
        }
        return Math.max.apply(Math, patterns.map(function (pattern) { return pattern.Ordinal; })) + 1;
    };
    PromotionHelperService.prototype.checkMessageName = function (patternGroup) {
        var _this = this;
        var promoId = this.parent.get("Id").value;
        var campaignId = this.parent.get("CampaignId").value;
        var useMessagePattern = patternGroup.value.PatternGroupType === "MultiMessage";
        return function (control) {
            return observableTimer(500).pipe(switchMap(function () {
                if (!control || !control.touched || control.value.length <= 0 || control.value === _this.emptyPositionMessageName) {
                    // Don't bother checking, return all is okay.
                    return observableOf(null);
                }
                var checkeMessageModel = {
                    PromotionId: promoId,
                    CampaignId: campaignId,
                    MessageName: control.value
                };
                return observableFrom(_this.promotionMessageService.checkUniqueMessageName(checkeMessageModel)).pipe(map(function (result) {
                    if (result) {
                        var filteredList = void 0;
                        //Now check that we dont have any duplicate message names on the active promo
                        if (useMessagePattern) {
                            //filter out no size row too
                            filteredList = patternGroup.value.MessagePatterns.filter(function (ptn) { return ptn.MessageName && ptn.MessageName.toLowerCase() === control.value.toLowerCase(); })
                                .map(function (ptn) { return ptn.TempMessageId; })
                                .filter(function (value, index, self) {
                                return self.indexOf(value) === index; // return unique tempMessageId
                            });
                        }
                        else {
                            filteredList = _this.PatternGroups.value.reduce(function (accumlator, current) {
                                return accumlator.concat(current.MessagePatterns.filter(function (pattern) { return pattern.MessageName.toLowerCase() === control.value.toLowerCase(); }).map(function (msg) { return msg.MessageName; }));
                            }, []);
                        }
                        if (filteredList.length > 1) {
                            if (useMessagePattern) {
                                return { "patternNameExists": true };
                            }
                            else {
                                return { "messageNameUniqueToCampaign": true };
                            }
                        }
                    }
                    else {
                        return { "messageNameUniqueToCampaign": true };
                    }
                }));
            }));
        };
    };
    /**
     * Will update the given patternGroup markets with the passed in market list
     * @param patternGroup - Pattern group that has the markets to be updated
     * @param markets - List of markets to use for updating the patternGroup markets.
     */
    PromotionHelperService.prototype.updateMarketCtrlAfterCalculation = function (patternGroup, markets) {
        var marketItems = patternGroup.controls.Markets;
        var marketDictionary = [];
        this.marketService.updateMarkets(marketItems, markets, marketDictionary);
    };
    PromotionHelperService.prototype.updateMarketCtrlAfterCalculationPriorityFill = function (marketItems, markets) {
        var marketDictionary = [];
        this.marketService.updateMarkets(marketItems, markets, marketDictionary);
    };
    PromotionHelperService.prototype.getCurrentMessagesFromForm = function (promoForm) {
        return this.getCurrentMessages(promoForm.value.messageForm.PatternGroups);
    };
    PromotionHelperService.prototype.getCurrentMessages = function (patternGroups) {
        var currentMessages = [];
        patternGroups.forEach(function (patternGroup) {
            patternGroup.MessagePatterns.forEach(function (message) {
                if (!message.IsEmpty) {
                    currentMessages.push(message);
                }
            });
        });
        return currentMessages;
    };
    PromotionHelperService.prototype.getPatternGroupForMessageFromForm = function (promoForm, tempMessageId) {
        return this.getPatternGroupForMessage(promoForm.value.messageForm.PatternGroups, tempMessageId);
    };
    PromotionHelperService.prototype.getPatternGroupForMessage = function (patternGroups, tempMessageId) {
        var result;
        patternGroups.some(function (patternGroup) {
            var messageFound = false;
            patternGroup.MessagePatterns.some(function (message) {
                if (message.TempMessageId === tempMessageId) {
                    result = patternGroup;
                    // If found, 'some' loop will stop.
                    messageFound = true;
                }
                // Keep the loop going
                return messageFound;
            });
            // If found, 'some' loop will stop.
            return messageFound;
        });
        return result;
    };
    /**
     * Get a patternGroup control for a given TempPatternGroupId
     * @param promoForm
     * @param TempPatternGroupId
     */
    PromotionHelperService.prototype.getPatternGroup = function (promoForm, TempPatternGroupId) {
        var patternGroupCtrls = this.PatternGroups;
        for (var i = 0; i < patternGroupCtrls.controls.length; i++) {
            var patternGroupCtrl = patternGroupCtrls.controls[i];
            if (patternGroupCtrl.value.TempPatternGroupId === TempPatternGroupId) {
                return patternGroupCtrl;
            }
        }
    };
    /**
     * Get a current list of patternGroups as the PromotionmessagePatternGroupModel
     * @param promoForm
     */
    PromotionHelperService.prototype.getCurrentPatternGroupsFromForm = function (promoForm) {
        if (!this.PatternGroups) {
            return [];
        }
        return this.PatternGroups.controls.map(function (item) { return item.value; });
    };
    Object.defineProperty(PromotionHelperService.prototype, "PatternGroups", {
        get: function () {
            return this.parent.controls.messageForm.get("PatternGroups");
        },
        enumerable: true,
        configurable: true
    });
    PromotionHelperService.prototype.MessagePatterns = function (patternGroup) {
        return patternGroup.get("MessagePatterns");
    };
    PromotionHelperService.prototype.MessageMarkets = function (patternGroup) {
        return patternGroup.get("Markets");
    };
    PromotionHelperService.prototype.getFocusableMessageInput = function () {
        return this.msgHtmlName + (this.msgIndexer - 1);
    };
    PromotionHelperService.prototype.CheckIfInvalid = function (control) {
        return control.invalid && (control.dirty || control.touched);
    };
    PromotionHelperService.prototype.PlacementsExist = function (promoForm) {
        var holderPlacements = promoForm.get("placementsFrmArr");
        var elementPlacements = promoForm.get("placementElementsFrmArr");
        return (holderPlacements && holderPlacements.length > 0) || (elementPlacements && elementPlacements.length > 0);
    };
    PromotionHelperService.prototype.DisableReturn = function (existingValue, newValue) {
        //Look for the new line and just set value back to its original value
        if (newValue && newValue.indexOf("\n") > -1) {
            existingValue.setValue(newValue.replace("\n", ""));
        }
    };
    PromotionHelperService.prototype.patchMessageIdsAfterSave = function (saveModel) {
        var _this = this;
        // Create dictionary of Message names and Ids
        var msgDict = {};
        saveModel.PatternGroups.forEach(function (pg) {
            pg.MessagePatterns.forEach(function (p) {
                if (!msgDict[p.MessageName]) {
                    msgDict[p.MessageName] = p.PromotionMessageId;
                }
            });
        });
        this.PatternGroups.controls.forEach(function (pgCtrl) {
            _this.MessagePatterns(pgCtrl).controls.forEach(function (msgCtrl) {
                var msgName = msgCtrl.get("MessageName").value;
                if (msgName && msgName !== _this.emptyPositionMessageName && msgDict[msgName]) {
                    msgCtrl.patchValue({ PromotionMessageId: msgDict[msgName] }, { emitEvent: true });
                }
            });
        });
    };
    return PromotionHelperService;
}());
export { PromotionHelperService };
