<div [formGroup]="parent" class="mt-4">
    <div formArrayName="placementsFrmArr">
        <ng-container *ngIf="shouldShowPlacements()">
            <div *ngFor="let placementGroup of placementsFrmArr.controls; let groupIndex=index" [formGroupName]="groupIndex" attr.stickyHeader="{{placementGroup.get('DisplayName').value}}">
                <div class="card ips-shadow mb-4">
                    <div class="card-body ips-blue-bg pb-0">
                        <div class="form-row d-flex">
                            <div class="flex-grow-1 pl-2">
                                <div class="row">
                                    <div class="col-6 form-group">
                                        <label translate>FIXTURE_GROUP_HOLDER</label>
                                        <div class="input-group ips-textarea">
                                            <textarea class="form-control form-control-lg ips-white-bg border-right" type="text" readonly formControlName="DisplayName" rows="1" ipsAutoGrow></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="!useMessagePattern">
                                    <div class="col-sm-6 col-lg-4 form-group">
                                        <label translate>LIMIT_TYPE</label>
                                        <select class="form-control form-control-lg" formControlName="SelectedPatternType">
                                            <option value="None" translate>NONE</option>
                                            <option value="WithinFixture" translate>APPLY_HOLDER_QTY_LIMIT_FOR_EACH_FIXTURE</option>
                                            <option value="AcrossFixtures" translate>APPLY_HOLDER_QTY_LIMIT_FOR_EACH_LOCATION</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-6 col-lg-4 form-group" *ngIf="placementGroup.get('SelectedPatternType').value !== 'None'">
                                        <div class="form-group">
                                            <label translate>POSITION_LIMIT_LOCATION_BALANCE</label>
                                            <select class="form-control form-control-lg" formControlName="SelectedPosition">
                                                <option *ngFor="let option of placementGroup.value.PositionLocationCountOptions" [value]="option.Position">{{option.Position}}&nbsp;({{option.LocationCount | number}})</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button class="text-left button-no-style tooltip-container" (click)="removePlacement(groupIndex)" ngbTooltip="{{'FIXTURE_GROUP_HOLDER_DELETE' | translate}}" placement="bottom-right">
                                    <i class="fa fa-remove fa-lg remove-icon pl-0"></i>
                                </button>
                            </div>
                        </div>

                        <ng-container *ngIf="useMessagePattern">
                            <!--Placement Group controls-->
                            <div class="form-row">
                                <div class="col form-group mb-0">
                                    <label translate>QUANTITIES</label>
                                </div>
                            </div>
                            <div class="card ips-shadow mb-4">
                                <div class="card-body">
                                    <div class="form-row">
                                        <div class="col-sm-6 col-lg-4 form-group text-center">
                                            <label translate>APPLY_PATTERN</label>
                                            <div class="input-group mb-3 option-control ips-market-button">
                                                <input type="text" class="form-control form-control-lg" readonly (click)="patternTypeClick(placementGroup)" value="{{(placementGroup.value.PatternTypeEnum === 'WithinFixture' ? 'WITHIN_FIXTURE' : 'ACROSS_FIXTURE') | translate}}">
                                                <div class="input-group-append">
                                                    <button class="btn btn-default" type="button" (click)="patternTypeClick(placementGroup)"><i class="fa fa-lg ips-gray-color px-1" [ngClass]="{'campaign-within-fixture-icon': placementGroup.value.PatternTypeEnum === 'WithinFixture' || placementGroup.value.PatternTypeEnum === 'None', 'campaign-across-fixture-icon': placementGroup.value.PatternTypeEnum === 'AcrossFixtures'}" ></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-lg-4 form-group text-center">
                                            <div class="form-group">
                                                <label translate>POSITION_LIMIT_LOCATION_BALANCE</label>
                                                <select class="form-control form-control-lg" formControlName="SelectedPosition">
                                                    <option *ngFor="let option of placementGroup.value.PositionLocationCountOptions" [value]="option.Position">{{option.Position}}&nbsp;({{option.LocationCount | number}})</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-lg-4 form-group text-center">
                                            <div class="form-group">
                                                <label translate>POSITION_QTY</label>
                                                <div class="form-control input form-control-lg placement-label px-1">{{placementGroup.value.PositionQty | number}}</div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-lg-4 form-group text-center mb-0">
                                            <div class="form-group mb-0">
                                                <label translate>ADDITIONAL_QTY</label>
                                                <div class="input-group ips-textarea">
                                                    <input type="number"
                                                        class="form-control form-control-lg text-center px-1"
                                                        name="{{'qtyInc' + placementGroup.value.QuantityIncreaseIdName}}"
                                                        ngbTooltip="{{'TOOLTIP_PLACEMENT_QTY_INCREASE' | translate}}"
                                                        placement="bottom"
                                                        formControlName="QuantityIncrease"/>
                                                    <div class="input-group-append">
                                                        <button type="button" class="btn btn-default last" (click)="patternQtyIncreaseTypeClick(placementGroup)"
                                                                placement="bottom" ngbTooltip="{{getIncreaseTargetTooltip()}}" translate>
                                                            {{getIncreaseTargetNameKey(placementGroup.value.QuantityIncreaseTarget)}}
                                                        </button>
                                                    </div>
                                                </div>
                                                <ips-show-errors [control]="placementGroup.get('QuantityIncrease')" [errorMessages]="getErrorMessages()"></ips-show-errors>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-lg-4 form-group text-center mb-0">
                                            <div class="form-group mb-0">
                                                <label translate>FULFILLMENT_QTY</label>
                                                <div class="input-group ips-textarea">
                                                    <input type="number"
                                                        class="form-control form-control-lg text-center px-1"
                                                        name="{{'fulQty' + placementGroup.value.IdName}}"
                                                        ngbTooltip="{{'TOOLTIP_PLACEMENT_FULFILL' | translate}}"
                                                        placement="bottom"
                                                           formControlName="FulfillmentOperand">
                                                    <div class="input-group-append">
                                                        <button type="button" class="btn btn-default last" (click)="patternFulfillmentOperatorClick(placementGroup)"
                                                            placement="bottom" ngbTooltip="{{'TOOLTIP_PLACEMENT_FULFILL_TYPE' | translate}}">
                                                            <i class="fa {{getOperatorIconName(placementGroup.value.FulfillmentOperator)}}" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <ips-show-errors [control]="placementGroup.get('FulfillmentOperand')" [errorMessages]="getErrorMessages()"></ips-show-errors>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-lg-4 form-group text-center mb-0">
                                            <div class="form-group mb-0">
                                                <label translate>FINAL_QTY</label>
                                                <span class="form-control input form-control-lg placement-label px-1"
                                                    [ngClass]="{'placement-final-qty-warning': placementGroup.value.FinalQuantity === 0}">
                                                    {{placementGroup.value.FinalQuantity | number}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <div class="form-row mb-4">
                            <div class="col-12">
                                <app-custom-data-field-container area="PromotionPlacement" subArea="PlacementGroup"
                                    [linkId]="placementGroup.get('Id').value"
                                    [parent]="placementGroup" minFieldSize="col-6"
                                    (valuesChanged)="defaultCdfValuesChanged($event, placementGroup)"
                                    [isDefault]="true"></app-custom-data-field-container>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col form-group mb-0">
                                <label class="" translate>MESSAGES</label>
                                <div formArrayName="MessagePlacements">
                                    <div *ngFor="let placement of placementGroup.controls.MessagePlacements.controls; let i=index" [formGroupName]="i" attr.stickyHeader="{{placement.value.MessagePlacement.PromotionMessageName}}">
                                        <div class="card ips-shadow mb-4">
                                            <div class="card-body pb-0">
                                                <div class="pb-3 ips-sticky-top-secondary bg-white d-flex align-items-center">
                                                    <div class="campaign-message-icon icon ips-icon-30 mr-2"></div>
                                                    <div class="flex-grow-1 mb-1 col-md-10">
                                                        {{placement.value.MessagePlacement.PromotionMessageName}}
                                                    </div>
                                                    <div *ngIf="!useMessagePattern">
                                                        <span class="pr-3"><b translate>PRINT_MESSAGE</b>:</span>
                                                        <div *ngIf="!useMessagePattern">
                                                            <select class="form-control form-control-sm" formControlName="PrintMessage">
                                                                <option [ngValue]="true" translate>YES</option>
                                                                <option [ngValue]="false" translate>NO</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr *ngIf="!useMessagePattern" class="mt-0 mb-4" />
                                                <div class="form-row mb-3" *ngIf="showNoFixtureMessage(placement.controls.MessagePlacementSizes) && placement.value.PrintMessage && placement.value.loaded">
                                                    <div class="col-12 text-center mb-4" translate>
                                                        NO_HOLDERS_AVAILABLE
                                                    </div>
                                                </div>
                                                <div [hidden]="!placement.value.PrintMessage" formArrayName="MessagePlacementSizes">
                                                    <div *ngFor="let placementSize of placement.controls.MessagePlacementSizes.controls; let s=index" [formGroupName]="s">
                                                        <div *ngIf="placementSize.value.Show" class="card ips-shadow mb-4" attr.stickyHeader="{{placementSize.value.Width | number:'1.0-9'}} x {{placementSize.value.Height | number:'1.0-9'}}">
                                                            <div class="card-header">
                                                                <div class="form-row d-flex ">
                                                                    <div class="flex-grow-1 pl-2">
                                                                        {{placementSize.value.Width | number:'1.0-9'}} x {{placementSize.value.Height | number:'1.0-9'}} - {{placement.value.FixtureTypeName}} - {{placement.value.HolderName}}<span *ngIf="placementSize.value.HolderVersionInfoName"> - {{placementSize.value.HolderVersionInfoName}}</span>
                                                                    </div>
                                                                    <div>
                                                                        <div class="row pt-2 pr-2">
                                                                            <span translate>LOCATION_BALANCE</span>

                                                                            <button class="btn badge badge-pill badge-secondary ml-2" type="button" (click)="showLocationBalanceModal(placementSize, placementGroup)">
                                                                                {{placementSize.value.LocationCount | number}}
                                                                            </button>
                                                                        </div>
                                                                        <div class="row pt-2 " *ngIf="!useMessagePattern">
                                                                            <span class="pr-3"><b translate>PRINT_SIZE</b>:</span>
                                                                            <div class="col pl-0">
                                                                                <select class="form-control form-control-sm" formControlName="Selected">
                                                                                    <option [ngValue]="true" translate>YES</option>
                                                                                    <option [ngValue]="false" translate>NO</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="card-body pb-4">
                                                                <!--Patterns-->
                                                                <div *ngIf="useMessagePattern">
                                                                    <div class="form-row">
                                                                        <div class="col col-md-12 col-lg-4 form-group text-center">
                                                                            <label class="ml-0" translate>CUSTOMER_ITEM_CODE</label>
                                                                            <input type="text"
                                                                                   class="form-control input form-control-lg px-1 text-center"
                                                                                   formControlName="CustomerItemCode"
                                                                                   name="{{'custItemCode' + placementSize.value.IdName}}">
                                                                            <ips-show-errors [control]="placementSize.get('CustomerItemCode')" [errorMessages]="getErrorMessages()"></ips-show-errors>
                                                                        </div>
                                                                        <div class="col col-md-6 col-lg-2 form-group text-center">
                                                                            <label class="ml-0" translate>POSITION_QTY</label>
                                                                            <div class="form-control input form-control-lg placement-label px-1">{{placementSize.value.HolderCount | number}}</div>
                                                                        </div>
                                                                        <div class="col col-md-6 col-lg-2 form-group text-center">
                                                                            <label class="ml-0" translate>ADDITIONAL_QTY</label>
                                                                            <div class="form-control input form-control-lg placement-label px-1">{{placementSize.value.QuantityIncrease | number}}</div>
                                                                        </div>
                                                                        <div class="col col-md-6 col-lg-2 form-group text-center">
                                                                            <label class="ml-0" translate>FULFILLMENT_QTY</label>
                                                                            <div class="form-control input form-control-lg placement-label px-1">{{placementSize.value.FulfillmentOperand | number}}<span class="ml-1" *ngIf="placementGroup.value.FulfillmentOperator === 'Percent'">%</span></div>
                                                                        </div>
                                                                        <div class="col col-md-6 col-lg-2 form-group text-center">
                                                                            <label class="ml-0" translate>FINAL_QTY</label>
                                                                            <div class="form-control input form-control-lg placement-label px-1">{{placementSize.value.FinalQuantity | number}}</div>
                                                                        </div>
                                                                        <div class="col col-md-6 col-lg-3 form-group text-center">
                                                                            <label class="ml-0" translate>CATEGORY_CODE</label>
                                                                            <input type="text" class="form-control input form-control-lg px-1 text-center" formControlName="CategoryCode" readonly />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!--Non-Patterns-->
                                                                <div *ngIf="!useMessagePattern">
                                                                    <div class="form-row">
                                                                        <div class="col col-md-12 col-lg-4 form-group text-center">
                                                                            <label class="ml-0" translate>CUSTOMER_ITEM_CODE</label>
                                                                            <input type="text"
                                                                                   class="form-control input form-control-lg px-1 text-center"
                                                                                   formControlName="CustomerItemCode"
                                                                                   name="{{'custItemCode' + placementSize.value.IdName}}">
                                                                            <ips-show-errors [control]="placementSize.get('CustomerItemCode')" [errorMessages]="getErrorMessages()"></ips-show-errors>
                                                                        </div>
                                                                        <div class="col-12 col-lg form-group text-center">
                                                                            <label class="mx-0 text-nowrap" translate>HOLDER_COUNT</label>
                                                                            <div class="form-control input form-control-lg placement-label px-1">{{placementSize.value.HolderCount | number}}</div>
                                                                        </div>
                                                                        <div class="col-12 col-lg form-group text-center">
                                                                            <label class="mx-0" translate>ADDITIONAL_QTY</label>

                                                                            <button class="text-left button-no-style tooltip-container"
                                                                                    ngbTooltip="{{'ADDITIONAL_QTY_MESSAGE' | translate}}"
                                                                                    placement="bottom-right">
                                                                                <i class="fa fa-info-circle pt-2"></i>
                                                                            </button>

                                                                            <div class="input-group ips-textarea">
                                                                                <input type="number"
                                                                                       class="form-control form-control-lg text-center px-1"
                                                                                       name="{{'qtyInc' + placementSize.value.QuantityIncreaseIdName}}"
                                                                                       ngbTooltip="{{'TOOLTIP_PLACEMENT_QTY_INCREASE' | translate}}"
                                                                                       placement="bottom"
                                                                                       formControlName="QuantityIncrease" />
                                                                                <div class="input-group-append">
                                                                                    <button type="button" class="btn btn-default last" (click)="qtyIncreaseTypeClick(placementGroup, placementSize)"
                                                                                            placement="bottom" ngbTooltip="{{getIncreaseTargetTooltip()}}" translate
                                                                                            [disabled]="!placementSize.value.Selected">
                                                                                        {{getIncreaseTargetNameKey(placementSize.value.QuantityIncreaseTarget)}}
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <ips-show-errors [control]="placementSize.get('QuantityIncrease')" [errorMessages]="getErrorMessages()"></ips-show-errors>
                                                                        </div>
                                                                        <div class="col-12 col-lg form-group text-center">
                                                                            <label class="mx-0" translate>FULFILLMENT_QTY</label>
                                                                            <div class="input-group ips-textarea">
                                                                                <input type="number"
                                                                                       class="form-control form-control-lg text-center px-1"
                                                                                       name="{{'fulQty' + placementSize.value.IdName}}"
                                                                                       ngbTooltip="{{'TOOLTIP_PLACEMENT_FULFILL' | translate}}"
                                                                                       placement="bottom"
                                                                                       formControlName="FulfillmentOperand">
                                                                                <div class="input-group-append">
                                                                                    <button type="button" class="btn btn-default last" (click)="fulfillmentOperatorClick(placementGroup, placementSize)"
                                                                                            placement="bottom" ngbTooltip="{{'TOOLTIP_PLACEMENT_FULFILL_TYPE' | translate}}"
                                                                                            [disabled]="!placementSize.value.Selected">
                                                                                        <i class="fa {{getOperatorIconName(placementSize.value.FulfillmentOperator)}}" aria-hidden="true"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <ips-show-errors [control]="placementSize.get('FulfillmentOperand')" [errorMessages]="getErrorMessages()"></ips-show-errors>
                                                                        </div>
                                                                        <div class="col-12 col-lg form-group text-center">
                                                                            <label class="mx-0" translate>FINAL_QTY</label>
                                                                            <div class="form-control input form-control-lg placement-label px-1">{{placementSize.value.FinalQuantity | number}}</div>
                                                                        </div>
                                                                        <div class="col col-md-12 col-lg-4 form-group text-center">
                                                                            <label class="ml-0" translate>CATEGORY_CODE</label>
                                                                            <input type="text" class="form-control input form-control-lg px-1 text-center" formControlName="CategoryCode" readonly />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="form-row">
                                                                    <div class="col-12">
                                                                        <app-custom-data-field-container area="PromotionPlacement" subArea="PlacementFixture" [linkId]="placementSize.get('Id').value" [parent]="placementSize" minFieldSize="col-6" [defaultValues]="GetPlacementGroupDefaultCdf(placementGroup)"></app-custom-data-field-container>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
