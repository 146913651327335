import {distinctUntilChanged, debounceTime, mergeMap, timeout} from "rxjs/operators";
import { Injectable } from "@angular/core";
import { AbstractControl, Validators, ValidatorFn, FormArray, FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { Observable, Observer, from } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

import { IpsModalService } from "imagine-ui-ng-modal";
import { ActiveProfileService, SearchInfo, SearchResponse } from "imagine-ui-ng-core";
import { LocationGroupSubGroupModel, FixtureGroupService, LocationGroupModel, SimpleSearchLocationGroupModel, LocationService, LocationGroupService, FixtureGroupWithFixturesModel, SimpleSearchFixtureGroupModel } from "../../imagine-ui-ng-store-profile";

import { MarketDisplayInfoLocationSubGroup } from "../model/MarketDisplayInfoLocationSubGroup";
import { MarketModel } from "../model/MarketModel";
import { MarketEditUIModel } from "../model/MarketEditUIModel";
import { MarketSearchModalReturnModel } from "../model/MarketSearchModalReturnModel";
import { MarketDisplayInfoLocation } from "../model/MarketDisplayInfoLocation";
import { MarketSearchModalComponent } from "../market-search-modal/market-search-modal.component";
import { LinkedFeatureSearchModalComponent } from "../linked-feature-search-modal/linked-feature-search-modal.component";
import { LocationAttributeSearchModalComponent } from "../location-attribute-search-modal/location-attribute-search-modal.component";
import { ProfileQuestionModel } from "../model/ProfileQuestionModel";
import { MarketType } from "../type/MarketType";
import { MarketGroupModel } from "../model/MarketGroupModel";
import { LocationAttributeSearchModel } from "../model/LocationAttributeSearchModel";
import { MarketDisplayInfo } from "../model/MarketDisplayInfo";
import { MarketGroupService } from "./market-group.service";
import { LocationAdvancedSimpleSearchModel } from "../../imagine-ui-ng-store-profile";

@Injectable()
export class MarketService {

    private marketHtmlName = "MrktHtml";
    private marketIndexer = 0;
    public markets: MarketEditUIModel[] = [];
    public featureInAudience = [];

    constructor(private translateService: TranslateService, private activeProfileService: ActiveProfileService, private ipsModal: IpsModalService, private locationService: LocationService,
        private locationGroupService: LocationGroupService, private fb: FormBuilder, private marketGroupService: MarketGroupService, private fixtureGroupService: FixtureGroupService) {
    }

    public newMarketFormGroup(ordinal: number = 1): FormGroup {
        const inputName = this.marketHtmlName + this.marketIndexer++;

        let marketCtrl = this.fb.group({
            Id: this.fb.control(0),
            MarketName: this.fb.control("", [Validators.required, this.checkMarketName()]),
            BusinessIdentity: this.fb.control(this.activeProfileService.businessIdentity),
            LocationCount: this.fb.control(0),
            LocationBalance: this.fb.control(0),
            OperationType: this.fb.control("Union"),
            TargetMarketType: this.fb.control(null),
            TargetMarketId: this.fb.control(0),
            Ordinal: this.fb.control(ordinal),
            PendingLocationCount: this.fb.control(0),
            InputName: this.fb.control(inputName),
            TypeaheadModalSelection: this.fb.control(null),

            TargetAttributeValue: this.fb.control(""),

            LocationGroupId: this.fb.control(0),
            LocationGroupName: this.fb.control(""),
            LocationSubGroupId: this.fb.control(0),
            LocationSubGroupName: this.fb.control(""),
        });

        this.addMarketListeners(marketCtrl);

        return marketCtrl;
    }

    public getMarketFormGroup(market: MarketModel): FormGroup {
        const inputName = this.marketHtmlName + this.marketIndexer++;
        let marketGrp = this.fb.group({
            Id: this.fb.control(market.Id),
            BusinessIdentity: this.fb.control(market.BusinessIdentity),
            LocationCount: this.fb.control(market.LocationCount),
            LocationBalance: this.fb.control(market.LocationBalance),
            OperationType: this.fb.control(market.OperationType),
            TargetMarketType: this.fb.control(market.TargetMarketType),
            TargetMarketId: this.fb.control(market.TargetMarketId),
            Ordinal: this.fb.control(market.Ordinal),
            PendingLocationCount: this.fb.control(market.PendingLocationCount),
            TargetAttributeValue: this.fb.control(market.TargetAttributeValue),
            InputName: this.fb.control(inputName),

            LocationGroupId: this.fb.control(0),
            LocationGroupName: this.fb.control(""),
            LocationSubGroupId: this.fb.control(0),
            LocationSubGroupName: this.fb.control(""),
        });

        //displayInfo
        let displayName;
        switch (market.TargetMarketType) {
            case "Location":
            case "LocationId":
                let locInfo = market.DisplayInfo as MarketDisplayInfoLocation;
                displayName = `${locInfo.LocationIdentifier} - ${locInfo.Name} - ${locInfo.City}, ${locInfo.StateProvince}`;
                break;
            case "LocationCityState":
                let locCity = market.DisplayInfo as MarketDisplayInfoLocation;
                displayName = `${locCity.City}, ${locCity.StateProvince}`;
                break;
            case "LocationState":
                let locState = market.DisplayInfo as MarketDisplayInfoLocation;
                displayName = `${locState.StateProvince} (${locState.StateFullName})`;
                break;
            case "LocationCountry":
                let locCountry = market.DisplayInfo as MarketDisplayInfoLocation;
                displayName = `${locCountry.Country} (${locCountry.CountryFullName})`;
                break;
            case "LocationPostalCode":
                let locPostal = market.DisplayInfo as MarketDisplayInfoLocation;
                displayName = locPostal.PostalCode;
                break;
            case "LocationSubGroup":
                let subGroupInfo = market.DisplayInfo as MarketDisplayInfoLocationSubGroup;
                displayName = `${subGroupInfo.LocationGroupName} - ${subGroupInfo.LocationSubGroupName}`;
                marketGrp.patchValue({
                    LocationGroupId: subGroupInfo.LocationGroupId,
                    LocationGroupName: subGroupInfo.LocationGroupName,
                    LocationSubGroupId: subGroupInfo.LocationSubGroupId,
                    LocationSubGroupName: subGroupInfo.LocationSubGroupName,
                });
                break;
            case "MarketGroup":
                let marketGroupInfo = market.DisplayInfo as MarketDisplayInfo;
                displayName = marketGroupInfo.Name;
                break;
            case "FixtureGroup":
                let fgInfo = market.DisplayInfo as MarketDisplayInfo;
                displayName = fgInfo.Name;
                break;
            case "Fixture":
                let fInfo = market.DisplayInfo as MarketDisplayInfo;
                displayName = fInfo.Name;
                break;
            case "AllExceptClosed":
                displayName = this.translateService.instant("ALL_EXCEPT_CLOSED");
                break;
        }

        marketGrp.addControl("TypeaheadModalSelection", this.fb.control(displayName));
        marketGrp.addControl("MarketName", this.fb.control(displayName, [Validators.required, this.checkMarketName()]));
        this.addMarketListeners(marketGrp);
        return marketGrp;
    }

    public addMarketListeners(marketCtrl: FormGroup): void {
        let marketNameCtrl = marketCtrl.get("MarketName") as FormControl;
        marketNameCtrl.valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe((value) => {
            if (!marketNameCtrl.valid) {
                marketCtrl.patchValue({ LocationCount: 0, LocationBalance: 0, TargetMarketId: 0, TargetMarketType: "" });
            }
        });

        //Add instant value change check for removing returns
        marketNameCtrl.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
            this.DisableReturn(marketNameCtrl, value);
        });
    }

    private DisableReturn(existingValue: FormControl, newValue: string) {
        //Look for the new line and just set value back to its original value
        if (newValue && newValue.indexOf("\n") > -1) {
            existingValue.setValue(newValue.replace("\n", ""));
        }
    }

    //Provides list of markets based on search term. These markets will be appended to an empty object as the ValidationInput property when typeahead-on-select is called from the template
    public getTypeAheadMarkets(val: string, searchType: string = null) {
        let searchParams: SearchInfo = { searchText: val, businessIdentity: this.activeProfileService.businessIdentity, chunkIndex: 0, recordCount: 20 };
        let markets: MarketEditUIModel[] = [];
        this.markets = [];
        if (searchType === "linkedprofile") {
            return this.locationGroupService.simpleSearch(searchParams).then(
                (result: SearchResponse<SimpleSearchLocationGroupModel>) => {

                    let market = <MarketEditUIModel>{};
                    this.updateMarketFromLocationGroupModel(result.ResultList, market);
                    markets = markets.concat(this.markets);
                    if (markets.length < 20) {
                        searchParams.recordCount = 20 - markets.length;
                        return this.fixtureGroupService.simpleSearch(searchParams).then(
                            (fixtureGroup: SearchResponse<SimpleSearchFixtureGroupModel>) => {
                                fixtureGroup.ResultList.forEach((item: SimpleSearchFixtureGroupModel) => {
                                    let marketUI = <MarketEditUIModel>{};
                                    this.updateMarketFromModel(item, marketUI, "FixtureGroup");
                                    markets.push(marketUI);
                                });
                                return markets;
                            });
                    } else {
                        return markets;
                    }
                });
        } else {
            if (val.length > 1) {
                let searches = ["LocationPostalCode", "LocationId", "LocationCityState", "LocationSubGroup", "LocationCountry", "LocationState"];
                return this.performSearch(searchParams, markets, searches);
            } else {
                return Promise.resolve(markets);
            }
        }
    }

    private performSearch(searchParams: SearchInfo, markets: MarketEditUIModel[], searches: string[]) {
        if (searches.length === 0 || markets.length >= 20) {
            return Promise.resolve(markets);
        }
        this.markets = [];
        const search = searches.pop() as MarketType;
        searchParams.recordCount = 20 - markets.length;
        if (search === "LocationSubGroup") {
            return this.locationGroupService.search(searchParams, "LocationGroup/Flattened/Search")
                .then((feature: SearchResponse<LocationGroupSubGroupModel>) => {

                    let market = <MarketEditUIModel>{};
                    this.updateMarketFromLocationGroupSubGroupModel(feature.ResultList, market);
                    markets = markets.concat(this.markets);
                    return this.performSearch(searchParams, markets, searches);
                });
        } else {
            searchParams.showUnassignedItems = true;
            return this.locationService.search(searchParams, "Location/AdvanceSimpleSearch")
                .then((feature: SearchResponse<LocationAdvancedSimpleSearchModel>) => {
                    feature.ResultList.forEach((item: LocationAdvancedSimpleSearchModel) => {
                        let market = <MarketEditUIModel>{};
                        this.updateMarketFromTypeAHeadPull(item, market);
                        markets.push(market);
                    });
                    return Promise.resolve(markets);
                });
        }
    }

    public createMarketSource(marketCtrl: AbstractControl): Observable<string> {
        let marketSource = Observable.create((observer: Observer<string>) => {
                // Runs on every search
                observer.next((marketCtrl.value as MarketEditUIModel).MarketName);
            }).pipe(debounceTime(365)
            , distinctUntilChanged()
            , mergeMap((marketName: string) => {
                return from(this.getTypeAheadMarkets(marketName));

            }));

        return marketSource;
    }

    public createFilteredMarketSource(marketCtrl: AbstractControl, fixtureGroupsInQuestion: ProfileQuestionModel[], featuresInQuestion: ProfileQuestionModel[], featuresInAudience: MarketModel[], fixturesInQuestion: ProfileQuestionModel[], searchType: string = null): Observable<string> {
        let marketSource = Observable.create((observer: Observer<string>) => {
                // Runs on every search
                observer.next((marketCtrl.value as MarketEditUIModel).MarketName);

        }).pipe(
            debounceTime(365),
            distinctUntilChanged(),
            mergeMap((marketName: string) => {
                return from(this.getTypeAheadMarkets(marketName, searchType).then((markets: MarketEditUIModel[]) => {
                    // filter out in use markets.
                    let filtered = markets.filter(loadedMarket => {
                        // If we are in an Audience/Market field:
                        //  - Don't show any LocationGroup or Subgroup used by a question (uses LocationGroup only)
                        //  - If another audience entry uses a LocationGroup, but a different subGroupId, allow it.
                        // If we are in a question:
                        //  - Don't allow a locationGroup used by another question or Audience/Market entry to be used

                        const loadedLocationGroupId = loadedMarket.TargetMarketType === "LocationGroup" ? (<MarketDisplayInfoLocationSubGroup>loadedMarket.DisplayInfo).LocationGroupId : 0;
                        const loadedSubGroupId = loadedMarket.TargetMarketType === "LocationSubGroup" ? loadedMarket.TargetMarketId : 0;
                        const loadedFixtureGroupId = loadedMarket.TargetMarketType === "FixtureGroup" ? loadedMarket.TargetMarketId : 0;
                        const loadedFixtureId = loadedMarket.TargetMarketType === "Fixture" ? loadedMarket.TargetMarketId : 0;

                        const questionsHaveLocationGroupId = featuresInQuestion.some(y => y.Id === loadedLocationGroupId);
                        const questionsHaveFixtureGroupId = fixtureGroupsInQuestion.some(f => f.Id === loadedFixtureGroupId);
                        const questionsHaveFixtureId = fixturesInQuestion.some(f => f.Id === loadedFixtureId);

                        const audienceHasMarket = featuresInAudience.some((x: MarketEditUIModel) =>
                            // Need this hear to dis-allow a sub-group from showing if already in use in the same audience.
                            (x.TargetMarketType === "LocationSubGroup" && x.TargetMarketId === loadedSubGroupId) ||
                            // This line is needed for when we are searching in a question, and we want to filter out an audience that is using that feature.
                            (x.TargetMarketType === "LocationSubGroup" && loadedMarket.TargetMarketType === "LocationGroup" && (<MarketDisplayInfoLocationSubGroup>x.DisplayInfo).LocationGroupId === loadedLocationGroupId) ||
                            (x.TargetMarketType === "LocationGroup" && x.TargetMarketId === loadedLocationGroupId) ||
                            (x.TargetMarketType === "LocationId" && x.TargetMarketType === loadedMarket.TargetMarketType && x.TargetMarketId === loadedMarket.TargetMarketId) ||
                            (x.TargetMarketType !== "LocationSubGroup" && x.TargetMarketType !== "LocationGroup" && x.TargetMarketType !== "LocationId" && x.TargetMarketType === loadedMarket.TargetMarketType && x.MarketName === loadedMarket.DisplayName));

                        return !audienceHasMarket && !questionsHaveLocationGroupId && !questionsHaveFixtureGroupId && !questionsHaveFixtureId;
                    });
                    return filtered;
                }));

            }));

        return marketSource;
    }

    public checkMarketName(): ValidatorFn {
        return (control: AbstractControl) => {
            if (control && control.value !== "" && control.parent) {
                //let market = control.parent.value as MarketEditUIModel;

                if (control.value !== control.parent.get("TypeaheadModalSelection").value) { // || !control.parent.get("TypeaheadModalSelection")
                    control.parent.get("TypeaheadModalSelection").setValue(null);
                    return { "invalidMarketName": true };
                }
                return null;
            }
            return null;
        };
    }

    public removeMarket(marketIndex: number, markets: FormArray): void {

        markets.removeAt(marketIndex);

        //Make sure there is at least one market input available to the user
        if (markets.length === 0) {
            markets.push(this.newMarketFormGroup(1));
        }

        // Update market ordinals.  Just start at beginning
        markets.controls.forEach(function (market, index) {
            market.patchValue({
                Ordinal: index + 1
            });
        });

        //If we remove first index then make sure the new index 0 has operation type of union
        if (marketIndex === 0) {
            markets.controls[0].get("OperationType").patchValue("Union");
        }

    }

    public updateMarketCtrl(item: MarketModel, market: FormGroup) {
        market.get("TypeaheadModalSelection").setValue(item.DisplayName);

        switch (item.TargetMarketType) {
            case "Location":
            case "LocationId":
                market.patchValue({
                    MarketName: item.DisplayName,
                    TargetMarketId: item.TargetMarketId,
                    TargetMarketType: item.TargetMarketType,
                    TargetAttributeValue: item.TargetAttributeValue,
                    LocationCount: item.LocationCount,
                    LocationGroupId: undefined,
                    LocationGroupName: undefined,
                    LocationSubGroupId: undefined,
                    LocationSubGroupName: undefined
                }, { emitEvent: true });
                break;
            case "LocationCityState":
                market.patchValue({
                    MarketName: item.DisplayName,
                    TargetMarketId: item.TargetMarketId,
                    TargetMarketType: item.TargetMarketType,
                    TargetAttributeValue: item.TargetAttributeValue,
                    LocationCount: item.LocationCount,
                    LocationGroupId: undefined,
                    LocationGroupName: undefined,
                    LocationSubGroupId: undefined,
                    LocationSubGroupName: undefined
                }, { emitEvent: true });
                break;
            case "LocationState":
                market.patchValue({
                    MarketName: item.DisplayName,
                    TargetMarketId: item.TargetMarketId,
                    TargetMarketType: item.TargetMarketType,
                    TargetAttributeValue: item.TargetAttributeValue,
                    LocationCount: item.LocationCount,
                    LocationGroupId: undefined,
                    LocationGroupName: undefined,
                    LocationSubGroupId: undefined,
                    LocationSubGroupName: undefined
                }, { emitEvent: true });
                break;
            case "LocationCountry":
                market.patchValue({
                    MarketName: item.DisplayName,
                    TargetMarketId: item.TargetMarketId,
                    TargetMarketType: item.TargetMarketType,
                    TargetAttributeValue: item.TargetAttributeValue,
                    LocationCount: item.LocationCount,
                    LocationGroupId: undefined,
                    LocationGroupName: undefined,
                    LocationSubGroupId: undefined,
                    LocationSubGroupName: undefined
                }, { emitEvent: true });
                break;
            case "LocationPostalCode":
                market.patchValue({
                    MarketName: item.DisplayName,
                    TargetMarketId: item.TargetMarketId,
                    TargetMarketType: item.TargetMarketType,
                    TargetAttributeValue: item.TargetAttributeValue,
                    LocationCount: item.LocationCount,
                    LocationGroupId: undefined,
                    LocationGroupName: undefined,
                    LocationSubGroupId: undefined,
                    LocationSubGroupName: undefined
                }, { emitEvent: true });
                break;
            case "LocationSubGroup":
                let subGroupInfo = item.DisplayInfo as MarketDisplayInfoLocationSubGroup;
                market.patchValue({
                    MarketName: item.DisplayName,
                    LocationCount: item.LocationCount,
                    TargetMarketId: item.TargetMarketId,
                    TargetMarketType: item.TargetMarketType,
                    TargetAttributeValue: undefined,
                    LocationGroupId: subGroupInfo ? subGroupInfo.LocationGroupId : undefined,
                    LocationGroupName: subGroupInfo ? subGroupInfo.LocationGroupName : undefined,
                    LocationSubGroupId: subGroupInfo ? subGroupInfo.LocationSubGroupId : undefined,
                    LocationSubGroupName: subGroupInfo ? subGroupInfo.LocationSubGroupName : undefined,
                }, { emitEvent: true });
                break;

            case "LocationGroup":
                market.patchValue({
                    MarketName: item.DisplayName,
                    TargetMarketId: item.TargetMarketId,
                    TargetMarketType: item.TargetMarketType,
                    TargetAttributeValue: undefined,
                    LocationGroupId: undefined,
                    LocationGroupName: undefined,
                    LocationSubGroupId: undefined,
                    LocationSubGroupName: undefined
                }, { emitEvent: true });
                break;
            case "FixtureGroup":
            case "Fixture":
            case "MarketGroup":
            case "AllExceptClosed":
                market.patchValue({
                    MarketName: item.DisplayName,
                    TargetMarketId: item.TargetMarketId,
                    TargetMarketType: item.TargetMarketType,
                    TargetAttributeValue: undefined,
                    LocationGroupId: undefined,
                    LocationGroupName: undefined,
                    LocationSubGroupId: undefined,
                    LocationSubGroupName: undefined
                }, { emitEvent: true });
                break;
        }
        //revalidate market name
        market.get("MarketName").updateValueAndValidity();
        market.markAsDirty();
    }

    /**
     * Update the given market formArray with the passed in markets list.
     * @param marketArray - FormArray of market controls to update
     * @param markets - Markets to make the formArray match
     * @param marketDictionary - Dictionary of markets by ordinal. If empty, will be filled in.
     */
    public updateMarkets(marketArray: FormArray, markets: MarketModel[], marketDictionary) {
        for (let j = 0; j < marketArray.length; j++) {
            let marketCtrl = marketArray.at(j);
            let marketObj = marketDictionary[marketCtrl.value.Ordinal];
            if (marketDictionary.length === 0) {
                marketObj = markets[j];
            }
            if (!marketObj) {
                marketObj = markets.filter(market => market.Ordinal === marketCtrl.value.Ordinal)[0];
                marketDictionary[marketCtrl.value.Ordinal] = marketObj;
            }
            marketCtrl.patchValue({
                LocationCount: marketObj.LocationCount,
                LocationBalance: marketObj.LocationBalance,
                PendingLocationCount: marketObj.PendingLocationCount
            });
        }
    }

    public showMarketSelectionModal(market: MarketEditUIModel, featuresInAudience: MarketModel[], featuresInQuestion: ProfileQuestionModel[],
        showInUseFeatures: boolean = false, disableByFeature: boolean = true, requestedMarketType: MarketType, fixtureGroupsInQuestion: ProfileQuestionModel[], fixturesInQuestion: ProfileQuestionModel[], isSingleSelect: boolean = false): Promise<MarketModel> {
        let promise;
        this.featureInAudience = featuresInAudience;
        if (market.TargetMarketType === null || market.TargetMarketType === undefined) {
            market.TargetMarketType = requestedMarketType;
        }
        // Currently LocationGroup/FixtureGroup marketType are only used by survey questions/LinkedProfile.
        // Normal audiences/markets use LocationSubGroup marketType only.
        if (requestedMarketType === "LocationGroup" || requestedMarketType === "FixtureGroup" || requestedMarketType === "Fixture") {
            // Show Feature (aka LocationGroup) / FixtureGroup selection modal.
            //requestedMarketType = "FixtureGroup"
            //tslint:disable-next-line: max-line-length
            promise = this.ipsModal.displayTemplateScrollable(LinkedFeatureSearchModalComponent, { featuresInAudience: featuresInAudience, featuresInQuestion: featuresInQuestion, fixtureGroupsInQuestion: fixtureGroupsInQuestion, fixturesInQuestion: fixturesInQuestion, requestedMarketType: requestedMarketType, isSingleSelect: isSingleSelect });
        } else {
            // show in use features in subGroup selection modal
            if (requestedMarketType === "LocationSubGroup" || requestedMarketType === "MarketGroup") {
                let requestedOrdinal = market.Ordinal;
                promise = this.ipsModal.displayTemplateScrollable(MarketSearchModalComponent, {
                    resolve:
                    {
                        showInUseFeatures: showInUseFeatures, featuresInAudience: featuresInAudience, disableByFeature: disableByFeature,
                        featuresInQuestion: featuresInQuestion, requestedMarketType: requestedMarketType, requestedOrdinal: requestedOrdinal
                    }});

            } else {
                promise = this.ipsModal.displayTemplateScrollable(LocationAttributeSearchModalComponent, {
                    resolve:
                    {
                        showInUseFeatures: showInUseFeatures, featuresInAudience: featuresInAudience
                    }
                });
            }

        }


        return promise
            .then((response: MarketSearchModalReturnModel) => {
                this.markets = [];
                switch (response.ResultType) {
                    case "Location":
                    case "LocationCityState":
                    case "LocationState":
                    case "LocationId":
                    case "LocationCountry":
                    case "LocationPostalCode":
                            let location = <LocationAttributeSearchModel[]>response.Result;
                            this.updateMarketFromLocationsModel(location, market, response.ResultType);
                        break;
                    case "LocationSubGroup":
                        let locGroupSubGroup = <LocationGroupSubGroupModel[]>response.Result;
                        this.updateMarketFromLocationGroupSubGroupModel(locGroupSubGroup, market);
                        break;
                    case "LocationGroup":
                        let locGroup = <LocationGroupModel[]>response.Result;
                        this.updateMarketFromLocationGroupModel(locGroup, market);
                        break;
                    case "MarketGroup":
                            let marketGroup = <MarketGroupModel[]>response.Result;
                            this.updateMarketsFromModel(marketGroup, market, response.ResultType);
                        break;
                    case "FixtureGroup":
                            let fixtureGroup = <FixtureGroupWithFixturesModel[]>response.Result;
                            this.updateMarketsFromModel(fixtureGroup, market, response.ResultType);
                        break;
                    //TODOL update for fixture
                    case "Fixture":
                            let fixture = <FixtureGroupWithFixturesModel[]>response.Result;
                            this.updateMarketsFromModel(fixture, market, response.ResultType);
                        break;
                }
                return this.markets;
            });
    }

    public updateMarketFromModel(model: MarketGroupModel | FixtureGroupWithFixturesModel | SimpleSearchFixtureGroupModel, market: MarketEditUIModel, marketType: MarketType) {
        market.TargetMarketId = model.Id;
        market.TargetMarketType = marketType;
        market.DisplayName = model.Name;
        market.DisplayInfo = <MarketDisplayInfo>{
            Id: model.Id,
            Name: model.Name,
            OptionType: marketType === "FixtureGroup" || marketType === "Fixture" ? "Number" : undefined
        };
        let marketPrefix = this.getMarketPrefix(market.TargetMarketType);

        market.DisplayNameWithPrefix = `${marketPrefix}${market.DisplayName}`;
    }
    public updateMarketsFromModel(models: any, prevmarket: MarketEditUIModel, marketType: MarketType) {
        let marketLength = this.featureInAudience.length ;
        if (marketLength === 1 ) {
            if (this.featureInAudience[0].TargetMarketType === null || this.featureInAudience[0].TargetMarketType === "") {
                marketLength = marketLength - 1;
            }
        }
        models.forEach(model => {
            if (model.Disabled) {
                let market = Object.assign({}, prevmarket);
                market.Disabled = true;
                market.Index = model.Index;
                this.markets.push(market);
            } else {
                let market = this.newMarketFormGroup(++marketLength).value;
                market.TargetMarketId = model.Id;
                market.TargetMarketType = marketType;
                market.DisplayName = model.Name;
                market.DisplayInfo = <MarketDisplayInfo>{
                    Id: model.Id,
                    Name: model.Name,
                    OptionType: marketType === "FixtureGroup" || marketType === "Fixture" ? "Number" : undefined
                };
                let marketPrefix = this.getMarketPrefix(market.TargetMarketType);
                market.DisplayNameWithPrefix = `${marketPrefix}${market.DisplayName}`;
                this.markets.push(market);
            }
        });
    }

    public updateMarketFromLocationsModel(locations: LocationAttributeSearchModel[], prevmarket: MarketEditUIModel, searchAttribute: MarketType ) {

        let marketLength = this.featureInAudience.length ;

        if (marketLength === 1 ) {
            if (this.featureInAudience[0].TargetMarketType === null || this.featureInAudience[0].TargetMarketType === "") {
                marketLength = marketLength - 1;
            }
        }
        locations.forEach(location => {
            if (location.Disabled) {
                let market = Object.assign({}, prevmarket);
                market.Disabled = true;
                market.Index = location.Index;
                this.markets.push(market);
            } else {
                let market = this.newMarketFormGroup(++marketLength).value;
                searchAttribute = location.SelectedLocationAttribute;
                market.TargetMarketId = location.Id;
                market.TargetMarketType = searchAttribute;
                market.LocationCount = location.LocationCount;
            switch (searchAttribute) {
                case "LocationCityState":
                    market.DisplayName = `${location.City}, ${location.StateProvince}`;
                    market.TargetAttributeValue = `${location.City},${location.StateProvince}`;
                    break;
                case "LocationState":
                    market.DisplayName = `${location.StateProvince} (${location.StateFullName})`;
                    market.TargetAttributeValue = `${location.StateProvince},${location.Country}`;
                    break;
                case "LocationId":
                    market.DisplayName = `${location.LocationIdentifier} - ${location.Name} - ${location.City}, ${location.StateProvince}`;
                    market.TargetAttributeValue = "";
                    break;
                case "LocationCountry":
                    market.DisplayName = `${location.Country} (${location.CountryFullName})`;
                    market.TargetAttributeValue = location.Country;
                    break;
                case "LocationPostalCode":
                    market.DisplayName = location.PostalCode;
                    market.TargetAttributeValue = location.PostalCode;
                    break;
            }
            market.DisplayInfo = <MarketDisplayInfoLocation>{
                Id: location.Id,
                Name: location.Name,
                LocationIdentifier: location.LocationIdentifier,
                City: location.City,
                StateProvince: location.StateProvince,
                StateFullName: location.StateFullName,
                Country: location.Country,
                CountryFullName: location.CountryFullName,
                PostalCode: location.PostalCode
            };
            let marketPrefix = this.getMarketPrefix(market.TargetMarketType);
            market.DisplayNameWithPrefix = `${marketPrefix}${market.DisplayName}`;
            this.markets.push(market);
            }
        });
    }
   public updateMarketFromLocationGroupSubGroupModel(locGroupSubGroups: LocationGroupSubGroupModel[], prevmarket: MarketEditUIModel) {
        let marketLength = this.featureInAudience.length;
        if (marketLength === 1 ) {
            if (this.featureInAudience[0].TargetMarketType === null || this.featureInAudience[0].TargetMarketType === "") {
                marketLength = marketLength - 1;
            }
        }
        locGroupSubGroups.forEach(locGroupSubGroup => {
            if (locGroupSubGroup.Disabled) {
                let market = Object.assign({}, prevmarket);
                market.Disabled = true;
                market.Index = locGroupSubGroup.Index;
                this.markets.push(market);
            } else {
                let market = this.newMarketFormGroup(++marketLength).value;
                market.TargetMarketId = locGroupSubGroup.LocationSubGroupId;
                market.DisplayName = `${locGroupSubGroup.LocationGroupName} - ${locGroupSubGroup.LocationSubGroupName}`;
                market.TargetMarketType = "LocationSubGroup";
                market.LocationCount = locGroupSubGroup.LocationCount;
                market.DisplayInfo = <MarketDisplayInfoLocationSubGroup>{
                    LocationGroupId: locGroupSubGroup.LocationGroupId,
                    LocationGroupName: locGroupSubGroup.LocationGroupName,
                    LocationSubGroupId: locGroupSubGroup.LocationSubGroupId,
                    LocationSubGroupName: locGroupSubGroup.LocationSubGroupName
                };
                let marketPrefix = this.getMarketPrefix(market.TargetMarketType);
                market.DisplayNameWithPrefix = `${marketPrefix}${market.DisplayName}`;
                this.markets.push(market);
            }
        });
    }
    public updateMarketFromLocationGroupModel(locGroups: any, prevmarket: MarketEditUIModel) {
        let marketLength = this.featureInAudience.length;
        if (marketLength === 1 ) {
            if (this.featureInAudience[0].TargetMarketType === null || this.featureInAudience[0].TargetMarketType === "") {
                marketLength = marketLength - 1;
            }
        }
        locGroups.forEach(locGroup => {
            if (locGroup.Disabled) {
                let market = Object.assign({}, prevmarket);
                market.Disabled = true;
                market.Index = locGroup.Index;
                this.markets.push(market);
            } else {
                let market = this.newMarketFormGroup(++marketLength).value;
                market.TargetMarketId = locGroup.Id;
                market.DisplayName = locGroup.Name;
                market.TargetMarketType = "LocationGroup";
                market.DisplayInfo = <MarketDisplayInfoLocationSubGroup>{
                    LocationGroupId: locGroup.Id,
                    LocationGroupName: locGroup.Name,
                    OptionType: locGroup.OptionType
                };
                let marketPrefix = this.getMarketPrefix(market.TargetMarketType);
                market.DisplayNameWithPrefix = `${marketPrefix}${market.DisplayName}`;
                this.markets.push(market);
            }
        });
    }

    public getMarketPrefix(marketType: string): string {
        let result = "";
        switch (marketType) {
            case "LocationCityState":
                result = this.translateService.instant("LOCATION_CITY");
                break;
            case "LocationCountry":
                result = this.translateService.instant("LOCATION_COUNTRY");
                break;
            case "Location":
            case "LocationId":
                result = this.translateService.instant("LOCATION_ID");
                break;
            case "LocationPostalCode":
                result = this.translateService.instant("LOCATION_POSTAL_CODE");
                break;
            case "LocationState":
                result = this.translateService.instant("LOCATION_STATE");
                break;
            case "LocationGroup":
            case "LocationSubGroup":
                result = this.translateService.instant("LOCATION_FEATURE");
                break;
            case "MarketGroup":
                result = this.translateService.instant("LOCATION_SEARCH");
                break;
            case "FixtureGroup":
                result = this.translateService.instant("FIXTURE_GROUP");
                break;
            case "Fixture":
                result = this.translateService.instant("FIXTURE");
                break;
        }
        return result ? `[${result}] - ` : result;
    }

    public updateMarketFromTypeAHeadPull(model: LocationAdvancedSimpleSearchModel, market: MarketEditUIModel) {
        market.TargetMarketId = model.TargetMarketId;
        market.TargetMarketType = model.MarketType;
        market.TargetAttributeValue = model.TargetAttribute;
        market.LocationCount = model.LocationCount;
        market.DisplayName = model.Label;

        let marketPrefix = this.getMarketPrefix(market.TargetMarketType);
        market.DisplayNameWithPrefix = `${marketPrefix}${market.DisplayName}`;
    }

    public updateMarketFromLocationAll(market: MarketEditUIModel) {
        market.TargetMarketId = 0;
        market.TargetMarketType = "AllExceptClosed";
        market.LocationCount = 0;

        market.DisplayName = this.translateService.instant("ALL_EXCEPT_CLOSED");

        market.DisplayInfo = <MarketDisplayInfoLocation>{
            Id: 0,
            Name: this.translateService.instant("ALL_EXCEPT_CLOSED")
        };

        let marketPrefix = this.getMarketPrefix(market.TargetMarketType);
        market.DisplayNameWithPrefix = `${marketPrefix}${market.DisplayName}`;
    }

}
