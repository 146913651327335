import { OnInit, EventEmitter, QueryList } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { String as IpsString } from "typescript-string-operations";
import { PromotionMessageService } from "../../index";
import { PromotionHelperService } from "../../service/promotionHelper.service";
import { CustomDataFieldContainerComponent } from "../../../shared/custom-data-field/custom-data-field-container/custom-data-field-container.component";
var MessagePriorityFillEditComponent = /** @class */ (function () {
    function MessagePriorityFillEditComponent(translateService, ipsMessage, promotionHelperService, promotionMessageService) {
        var _this = this;
        this.translateService = translateService;
        this.ipsMessage = ipsMessage;
        this.promotionHelperService = promotionHelperService;
        this.promotionMessageService = promotionMessageService;
        this.isClone = false;
        this.messageDeleted = new EventEmitter();
        this.messageAdded = new EventEmitter();
        this.messageOrdinalChanged = new EventEmitter();
        this.marketChanged = new EventEmitter();
        this.messageNameChanged = new EventEmitter();
        this.cdfsLoaded = new EventEmitter();
        this.reorderMessages = false;
        this.TranslateStrings = {
            "PLEASE_ENTER_VALID_MARKET": "",
            "MARKET_PENDING_LOCATIONS_WARNING": "",
            "EMPTY_POSITION": "",
            "MESSAGE_NAME_UNIQUE_TO_CAMPAIGN": "",
            "MESSAGE_PATTERN_NAME_NOT_UNIQUE": "",
            "PLEASE_ENTER_MESSAGE": "",
            "PLEASE_ENTER_MARKET": "",
            "MAX_LENGTH_ERROR": ""
        };
        this.errorMessages = {
            "required": function () { return _this.TranslateStrings["PLEASE_ENTER_MESSAGE"]; },
            "maxlength": function (params) { return IpsString.Format(_this.TranslateStrings["MAX_LENGTH_ERROR"], params.requiredLength); },
            "messageNameUniqueToCampaign": function () { return _this.TranslateStrings["MESSAGE_NAME_UNIQUE_TO_CAMPAIGN"]; },
            "patternNameExists": function () { return _this.TranslateStrings["MESSAGE_PATTERN_NAME_NOT_UNIQUE"]; },
            "invalidMarketName": function () { return _this.TranslateStrings["PLEASE_ENTER_VALID_MARKET"]; }
        };
        this.loadedMsgCount = 0;
        this.cdfLoadedCount = 0;
    }
    MessagePriorityFillEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translateService.get("ALL").subscribe(function () { return _this.TranslateText(); });
        this.promotionHelperService.tempMessageId = this.tempMessageId;
        this.promotionHelperService.emptyPositionMessageName = this.TranslateStrings["EMPTY_POSITION"];
        this.promotionHelperService.createForm(this.parent, this.messageNameChanged, true, "priority");
        if (!this.parent.get("ClearAllMessages").value) {
            this.loadedMsgCount = this.promotionHelperService.initializeForm(this.patternGroupsModel, this.isClone);
            //this.markets = this.patternGroupsModel && this.patternGroupsModel.length > 0 ? this.patternGroupsModel[0].Markets : [];
        }
        this.messageNames = this.promotionHelperService.getMessageSuggestions();
        this.getFocusableMessageInput();
    };
    MessagePriorityFillEditComponent.prototype.cdfLoadedHandler = function () {
        this.cdfLoadedCount++;
        if (this.cdfLoadedCount === this.loadedMsgCount) {
            this.cdfsLoaded.emit();
        }
    };
    MessagePriorityFillEditComponent.prototype.setSortableOptions = function (patternGroup) {
        var _this = this;
        //drag and drop
        //update event
        return this.sortableOptions = {
            onEnd: function (event) {
                _this.updateOrdinal(patternGroup, event);
            },
            handle: ".grab-cursor"
        };
    };
    MessagePriorityFillEditComponent.prototype.TranslateText = function () {
        for (var _i = 0, _a = Object.keys(this.TranslateStrings); _i < _a.length; _i++) {
            var key = _a[_i];
            this.TranslateStrings[key] = this.translateService.instant(key);
        }
    };
    MessagePriorityFillEditComponent.prototype.disableCreateMessagePatternButton = function (patternGroup) {
        return !this.MessagePatterns(patternGroup).valid;
    };
    MessagePriorityFillEditComponent.prototype.cloneMessage = function (patternGroup, pattern) {
        return;
    };
    MessagePriorityFillEditComponent.prototype.addNewPriority = function (patternGroup) {
        var pattern = this.promotionHelperService.newPriorityFillFormGroup(patternGroup);
        this.MessagePatterns(patternGroup).push(pattern);
        this.parent.markAsDirty();
        //raise event to parent
        this.messageAdded.emit(pattern.value);
        this.getFocusableMessageInput();
    };
    MessagePriorityFillEditComponent.prototype.getOriginalMarkets = function (patternGroupCtrl, patternGroup, index) {
        var foundItem;
        var msgPattern = patternGroup.get("MessagePatterns");
        if (patternGroup.value.MessagePatterns.length > index) {
            foundItem = patternGroup.value.MessagePatterns[index];
            if (foundItem) {
                if (!foundItem.Markets) {
                    return [];
                }
                return foundItem.Markets;
            }
        }
        return [];
    };
    MessagePriorityFillEditComponent.prototype.placementsExist = function () {
        // If there are any placements, there will be one for each message, no need to check for a placement on the specific message.
        return this.promotionHelperService.PlacementsExist(this.parent);
    };
    MessagePriorityFillEditComponent.prototype.removePatternPrompt = function (patternGroup, index, toolTip) {
        var _this = this;
        if (toolTip) {
            toolTip.close();
        }
        var pattern = patternGroup.value.MessagePatterns[index];
        var patternsWithMessageId = this.getPatternsWithTempMessageId(patternGroup, pattern.TempMessageId);
        // Only prompt the user to delete a pattern this is the last pattern referencing a specific message that has placements, otherwise delete it without prompting.
        // Or to be more explicit, Delete the pattern without prompting the user if any of the following is true:
        //// It is an Empty Postion.
        //// Other patterns still reference the same message.
        //// The message to be deleted doesn't have placements assigned yet.
        if (!pattern.IsEmpty && this.placementsExist() && patternsWithMessageId.length === 1) {
            var translated = this.translateService.instant("PROMPT_DELETE_PROMOTION_MESSAGE");
            this.ipsMessage.confirmDelete({ body: translated })
                .then(function (response) {
                if (response) {
                    _this.removePattern(patternGroup, index, patternsWithMessageId);
                }
            })
                .catch(function () {
                // rejection
            });
        }
        else {
            this.removePattern(patternGroup, index, patternsWithMessageId);
        }
    };
    MessagePriorityFillEditComponent.prototype.removePattern = function (patternGroup, index, duplicateMsgPatterns) {
        var msgPatterns = patternGroup.get("MessagePatterns");
        var patternToRemove = msgPatterns.at(index).value;
        var msgName = patternToRemove.MessageName;
        msgPatterns.removeAt(index);
        //if message name not exists any more, remove it from suggested message names
        var isEmptyPostion = msgName === this.TranslateStrings["EMPTY_POSITION"];
        var exists = patternGroup.value.MessagePatterns.filter(function (pattern) { return pattern.MessageName === msgName; }).length > 0;
        if (!isEmptyPostion && !exists) {
            this.messageNames.filter(function (name) { return name !== msgName; });
        }
        //update ordinal
        msgPatterns.controls.forEach(function (pattern, i) {
            pattern.patchValue({
                Ordinal: i + 1
            });
        });
        //filter returns an empty array if no matches are found, so broadcast Delete message if this was the only pattern referencing the same message
        if (patternToRemove.TempMessageId !== undefined && duplicateMsgPatterns.length <= 1) {
            this.messageDeleted.emit(patternToRemove);
        }
        else {
            //raise event to re-order placement and re-calculate holder counts
            this.messageOrdinalChanged.emit();
        }
        //delete markets if
        this.parent.markAsDirty();
    };
    MessagePriorityFillEditComponent.prototype.getPatternsWithTempMessageId = function (patternGroup, tempMessageId) {
        var patternsWithSameMessage = [];
        var msgPatterns = patternGroup.get("MessagePatterns");
        msgPatterns.controls.forEach(function (pattern) {
            if (pattern.value.TempMessageId === tempMessageId) {
                patternsWithSameMessage.push(pattern);
            }
        });
        return patternsWithSameMessage;
    };
    MessagePriorityFillEditComponent.prototype.marketChangedEventHandler = function (patternGroupCtrl) {
        this.marketChanged.emit(patternGroupCtrl.get("TempMessageId").value);
    };
    //jquery.ui.touch.punch prevents the input/textarea to be selected
    //this method set focus on input when it's clicked
    MessagePriorityFillEditComponent.prototype.setFocus = function (event) {
        var element = event.target;
        element.focus();
    };
    //drag and drop
    MessagePriorityFillEditComponent.prototype.updateOrdinal = function (patternGroup, event) {
        var oldIndex = event.oldIndex;
        var newIndex = event.newIndex;
        if (oldIndex !== newIndex) {
            //Update position so messages with cdf attached are shown first
            var messageName_1 = this.MessagePatterns(patternGroup).controls[newIndex].get("MessageName").value;
            for (var i = 0; i < this.MessagePatterns(patternGroup).length; i++) {
                var pattern = this.MessagePatterns(patternGroup).controls[i].value;
                if (pattern.MessageName === messageName_1) {
                    if (!pattern.IsCdfEnabled) {
                        var index = this.MessagePatterns(patternGroup).controls.findIndex(function (item) { return item.get("MessageName").value === messageName_1 && item.get("IsCdfEnabled").value; });
                        if (index !== -1) {
                            var temp = this.MessagePatterns(patternGroup).controls[i];
                            this.MessagePatterns(patternGroup).controls[i] = this.MessagePatterns(patternGroup).controls[index];
                            this.MessagePatterns(patternGroup).controls[index] = temp;
                        }
                        break;
                    }
                    else {
                        break;
                    }
                }
            }
            for (var i = 0; i < this.MessagePatterns(patternGroup).length; i++) {
                var pattern = this.MessagePatterns(patternGroup).controls[i];
                pattern.patchValue({
                    Ordinal: i + 1
                });
            }
            //raise event to re-order placement and re-calculate holder counts
            this.messageOrdinalChanged.emit();
            this.parent.markAsDirty();
        }
    };
    MessagePriorityFillEditComponent.prototype.getErrorMessages = function (key) {
        var _this = this;
        var msgs = Object.assign({}, this.errorMessages);
        if (key) {
            switch (key.toLowerCase()) {
                case "market":
                    msgs["required"] = function () { return _this.TranslateStrings["PLEASE_ENTER_MARKET"]; };
                    break;
                default:
            }
        }
        return msgs;
    };
    //auto focus
    MessagePriorityFillEditComponent.prototype.getFocusableMessageInput = function () {
        this.focusableMessageInput = this.promotionHelperService.getFocusableMessageInput();
    };
    MessagePriorityFillEditComponent.prototype.prepareSaveModel = function (promotionSaveModel) {
        var _this = this;
        var marketModel;
        marketModel = [];
        var patternGrp;
        patternGrp = [];
        if (this.PatternGroups.value[0]) {
            this.PatternGroups.value[0].MessagePatterns.forEach(function (mp) {
                var patternData = {
                    BusinessIdentity: _this.PatternGroups.value[0].BusinessIdentity,
                    Markets: mp.Markets,
                    Id: mp.Id,
                    PromotionId: _this.PatternGroups.value[0].PromotionId,
                    PatternGroupType: "PriorityMessage",
                    MessagePatterns: [],
                    MarketGroupId: mp.MarketGroupId,
                    Name: mp.MessageName,
                    PlacementGroups: null
                };
                patternData.MessagePatterns.push(mp);
                patternGrp.push(patternData);
            });
        }
        promotionSaveModel.PatternGroups = patternGrp;
        promotionSaveModel.PatternGroups.forEach(function (patternGroup) {
            patternGroup.MessagePatterns.forEach(function (msg) {
                if (msg.PromotionMessageId === null) {
                    // Clear out the name for empty positions, it must be empty for the backend to work
                    msg.MessageName = "";
                }
            });
            //update ordinal by index
            patternGroup.Markets.forEach(function (market, index) { return market.Ordinal = index + 1; });
        });
    };
    Object.defineProperty(MessagePriorityFillEditComponent.prototype, "PatternGroups", {
        get: function () {
            return this.parent.controls.messageForm.get("PatternGroups");
        },
        enumerable: true,
        configurable: true
    });
    MessagePriorityFillEditComponent.prototype.MessagePatterns = function (patternGroup) {
        return patternGroup.get("MessagePatterns");
    };
    MessagePriorityFillEditComponent.prototype.saveCustomData = function (saveModel) {
        this.promotionHelperService.patchMessageIdsAfterSave(saveModel);
        var promises = [];
        this.customDataFieldContainers.forEach(function (container) {
            var msgId = container.parent.value.PromotionMessageId;
            promises.push(container.save(msgId));
        });
        return promises;
    };
    MessagePriorityFillEditComponent.prototype.toggleReorderMessages = function (reorderMessages) {
        this.reorderMessages = reorderMessages;
    };
    return MessagePriorityFillEditComponent;
}());
export { MessagePriorityFillEditComponent };
