import { Component, OnInit, ViewChild, ChangeDetectorRef, ViewChildren, QueryList, ElementRef, AfterViewInit } from "@angular/core";
import { DatePipe, getLocaleDateFormat, FormatWidth } from "@angular/common";
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/core";
import { ActiveProfileService, LoggingService } from "imagine-ui-ng-core";
import { PageTitleService, CountryService, RoleService, ProfileService } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { String as IpsString, StringBuilder } from "typescript-string-operations";
import { HttpClient } from "@angular/common/http";
import {
    CampaignService, CampaignData, PromotionService, PromotionMessageService, PromotionMessagePatternGroupModel, PromotionMessageWithPlacementsModel, PlacementModalSearchResultModel, PlacementType,
    MessagePlacementCalculationResultModel, PromotionMessagePatternModel, MessageNameChangeModel, MessagePlacementCalculationModel, PromotionHeaderModel, PromotionSaveModel, PromotionModel, PromotionMessagePlacementGroupModel
} from "../../index";
import { IpsModalService } from "imagine-ui-ng-modal";
import { PlacementSearchModalComponent } from "../placement-search-modal/placement-search-modal.component";
import { PlacementElementEditComponent } from "../placement-element-edit/placement-element-edit.component";
import { PlacementEditComponent } from "../placement-edit/placement-edit.component";
import { PromotionHelperService } from "../../service/promotionHelper.service";
import { MessageEditComponent } from "../message-edit/message-edit.component";
import { MessagePatternEditComponent } from "../message-pattern-edit/message-pattern-edit.component";
import { MessagePriorityFillEditComponent } from "../message-priority-fill-edit/message-priority-fill-edit.component";
import { CustomDataFieldContainerComponent } from "../../../shared/custom-data-field/custom-data-field-container/custom-data-field-container.component";
import { validateAllFormFieldsWithDialog } from "../../../shared/validation/utils";
import { GeneralSettingsService } from "../../../shared/service/general-settings.service";
declare let $: any;

interface IDatePicker {
    opened: boolean;
}

type scrollToType = "message" | "fixtureHolder" | "element";

@Component({
    selector: "app-promotion-edit",
    templateUrl: "./promotion-edit.component.html",
    styleUrls: ["./promotion-edit.component.scss"],
    providers: [DatePipe]
})
export class PromotionEditComponent implements OnInit, AfterViewInit {
    @ViewChild(PlacementElementEditComponent) private placementElementComponent: PlacementElementEditComponent;
    @ViewChild(PlacementEditComponent) private placementComponent: PlacementEditComponent;
    @ViewChild(MessageEditComponent) private messageEditComponent: MessageEditComponent;
    @ViewChild(MessagePatternEditComponent) private messagePatternEditComponent: MessagePatternEditComponent;
    @ViewChild(CustomDataFieldContainerComponent) private customDataFieldContainerComponent: CustomDataFieldContainerComponent;
    @ViewChild(MessagePriorityFillEditComponent) private messagePrirorityFillEditComponent: MessagePriorityFillEditComponent;

    @ViewChildren(PlacementElementEditComponent, { read: ElementRef }) private placementElementElementRefs: QueryList<ElementRef>;
    @ViewChildren(PlacementEditComponent, { read: ElementRef }) private placementElementRefs: QueryList<ElementRef>;
    @ViewChildren(MessageEditComponent, { read: ElementRef }) private messageEditElementRefs: QueryList<ElementRef>;
    @ViewChildren(MessagePatternEditComponent, { read: ElementRef }) private messagePatternEditElementRefs: QueryList<ElementRef>;
    @ViewChildren(MessagePriorityFillEditComponent, { read: ElementRef }) private messagePriorityFillEditElementRefs: QueryList<ElementRef>;

    private placementElementRef: ElementRef;
    private placementElementElementRef: ElementRef;
    private messageEditElementRef: ElementRef;
    private messagePatternEditElementRef: ElementRef;
    private messagePriorityFillEditElementRef: ElementRef;

    public loaded = false;
    public promoLoaded = false;
    public promise: Promise<any>;
    public breadCrumbLabel: string;
    public dateFormat: string;
    public startDate: IDatePicker;
    public endDate: IDatePicker;
    public startDateOptions: any = {};
    public endDateOptions: any = {};
    public minPromotionDate: Date;
    public showInStoreDateWarning = false;
    public originalPromoName: string;
    public originalPromoStart: Date;
    public useMessagePattern = false;
    public showStartDateWarning = false;
    public showMessageEditComponent = false;
    private today: Date;
    private calendarTheme = "theme-default";
    private isManualStartDate = false;
    public StartDateText = "";
    public EndDateText = "";
    private promptDeleteBody = "";
    private calendarDateFormat = "";
    private promotionEndDateInvalid = "";
    private promotionNameValidationError = "";
    private promotionNameUniqueToCampaign = "";
    private promotionDeleteError = "";
    private promotionSaveError = "";
    private promotionStartDateInvalid = "PROMOTION_START_DATE_INVALID";
    private pleaseEnterPromoName = "";
    private pleaseEnterStartDate = "";
    private textMaxLength = "";
    private promotionStartBeforeInStore = "";
    private promotionStartBeforeInStoreFormat = "";
    public promotionId: number;
    public createMessaging: boolean;
    public reorderMessages = false;

    public campaignFulfillmentType: string;
    public campaignFulfillmentQty: number;
    public campaignElementQty: number;

    public enableSignPlanLayout = false;

    public promotionStatus: any[] = [];
    public selectedStatus: string;

    public errorMessages = {
        "required": () => "Field is required",
        "maxlength": (params) => IpsString.Format(this.textMaxLength, params.requiredLength),
        "promotionStartDateInvalid": () => this.promotionStartDateInvalid,
        "promotionEndDateInvalid": () => this.promotionEndDateInvalid
    };
    public tempMessageId = 1;
    private scrollToParam: scrollToType;
    private msgCdfsLoaded = false;
    private promoCdfsLoaded = false;
    private clonePromotionId: number; // Id to use when loading data
    public isCloneMode = false;

    //Form vars
    public campaignId: number;
    public campaignName: string;
    public campaignInStore: Date;
    public campaignStart: Date;
    public campaignEnd: Date;
    public promoForm: FormGroup;
    public Id: FormControl;
    public Name: FormControl;
    public StartDate: FormControl;
    public EndDate: FormControl;
    public Notes: FormControl;
    public selectedMessageType: string;
    public patternGroups: PromotionMessagePatternGroupModel[] = [];
    public promotionMessageAndPlacements: MessagePlacementCalculationResultModel[] = [];  //initialize to empty array

    constructor(private campaignService: CampaignService, private promotionService: PromotionService, private promotionMessageService: PromotionMessageService, private ipsMessage: IpsMessageService, private $state: StateService,
        private transition: Transition, private translateService: TranslateService, private pageTitleService: PageTitleService, private promotionHelperService: PromotionHelperService,
        private datePipe: DatePipe, private ipsModal: IpsModalService, private changeDetector: ChangeDetectorRef,
        private logger: LoggingService, private activeProfileService: ActiveProfileService,
        private settingsService: GeneralSettingsService) {
    }

    ngOnInit() {

        this.today = new Date();
        this.today.setHours(0, 0, 0, 0);    // set to midnight

        let localeName = this.activeProfileService.profile.Locale || navigator.language;
        this.dateFormat = getLocaleDateFormat(localeName, FormatWidth.Short).toUpperCase();

        this.startDate = {
            opened: false
        };
        this.endDate = {
            opened: false
        };

        this.startDateOptions = {
            minDate: this.today,
            containerClass: this.calendarTheme,
            showWeekNumbers: false,
            dateInputFormat: this.dateFormat
        };

        this.endDateOptions = {
            minDate: this.today,
            containerClass: this.calendarTheme,
            showWeekNumbers: false,
            dateInputFormat: this.dateFormat
        };

        this.campaignId = Number(this.transition.params().campaignId || 0);
        this.promotionId = Number(this.transition.params().id || 0);
        this.clonePromotionId = Number(this.transition.params().clonePromotionId || 0);
        this.isCloneMode = this.clonePromotionId > 0;
        if (this.isCloneMode) {
            this.promotionId = 0;
        }

        if (this.promotionId === 0) {
            this.selectedStatus = "Completed";
        }

        this.scrollToParam = this.transition.params().scrollTo;

        let pageTitle = this.promotionId === 0 ? "CREATE_PROMOTION" : "EDIT_PROMOTION";

        this.breadCrumbLabel = pageTitle;
        this.pageTitleService.setTitle([pageTitle]);

        this.promotionStatus = [
            { Id: 1, Status: "InProgress" },
            { Id: 2, Status: "Completed" }];

        this.createForm();

        let campaignIdToLoad = this.campaignId;
        let promoIdToLoad = this.isCloneMode ? this.clonePromotionId : this.promotionId;
        this.selectedMessageType = "ordinaryMessage";
        // If we got an ID to load, load it.
        if (campaignIdToLoad > 0) {
            //Initial call to populate screen on load
            this.getCampaign(campaignIdToLoad).then(() => {
                // If we got an ID to load, load it.
                if (promoIdToLoad > 0) {
                    //Initial call to populate screen on load
                    this.getPromo(promoIdToLoad);
                } else {
                    this.getSignPlanLayoutSetting().then(() => {
                        this.loaded = true;
                    });
                }
            });
        } else if (campaignIdToLoad === 0) {
            this.loaded = true;
        }

        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());

    }

    ngAfterViewInit() {
        this.messagePatternEditElementRefs.changes.subscribe((component: QueryList<ElementRef>) => {
            this.messagePatternEditElementRef = component.first;

            this.scrollToParamVal();
        });
        this.messageEditElementRefs.changes.subscribe((component: QueryList<ElementRef>) => {
            this.messageEditElementRef = component.first;

            this.scrollToParamVal();
        });
        this.messagePriorityFillEditElementRefs.changes.subscribe((component: QueryList<ElementRef>) => {
            this.messagePriorityFillEditElementRef = component.first;

            this.scrollToParamVal();
        });
        this.placementElementRefs.changes.subscribe((component: QueryList<ElementRef>) => {
            this.placementElementRef = component.first;

            this.scrollToParamVal();
        });
        this.placementElementElementRefs.changes.subscribe((component: QueryList<ElementRef>) => {
            this.placementElementElementRef = component.first;

            this.scrollToParamVal();
        });
    }

    private getSignPlanLayoutSetting(): Promise<any> {
        return this.settingsService.canEditSignPlanLayout().then((response: boolean) => {
            this.enableSignPlanLayout = response;
        });
    }

    public promoCdfsLoadedHandler() {
        this.promoCdfsLoaded = true;
        this.scrollToParamVal();
    }

    public messageCdfsLoadedHandler() {
        this.msgCdfsLoaded = true;
        this.scrollToParamVal();
    }

    private scrollToParamVal() {
        if (this.scrollToParam && this.promoCdfsLoaded) {
            let ele: HTMLElement;
            switch (this.scrollToParam) {
                case "message":
                    if (this.messageEditElementRef) {
                        //ele = this.messageEditElementRef.nativeElement;
                        ele = document.getElementById("messageEditCard");
                    } else if (this.messagePatternEditElementRef) {
                        //ele = this.messagePatternEditElementRef.nativeElement;
                        ele = document.getElementById("messageEditCard");
                    } else if (this.messagePriorityFillEditElementRef) {
                        //ele = this.messagePatternEditElementRef.nativeElement;
                        ele = document.getElementById("messageEditCard");
                    }
                    break;
                case "fixtureHolder":
                    if ((this.messageEditElementRef || this.messagePatternEditElementRef || this.messagePriorityFillEditElementRef) && this.placementElementRef && this.msgCdfsLoaded) {
                    //    ele = this.placementElementRef;
                        ele = document.getElementById("fixturePlacementCard");
                    }
                    break;
                case "element":
                    if ((this.messageEditElementRef || this.messagePatternEditElementRef || this.messagePriorityFillEditElementRef) && this.placementElementElementRef &&
                        this.placementElementRef && this.msgCdfsLoaded) {
                        //    ele = this.placementElementElementRef.nativeElement;
                        ele = document.getElementById("elementPlacementCard");
                    }
                    break;
                default:
                    this.scrollToParam = undefined;
                    break;
            }
            if (ele) {
                if (this.useMessagePattern && this.scrollToParam === "fixtureHolder") {
                    // TODO: can't figure out what is loading in patterns that makes fixtureHolder be off. doesn't seem to be CDFs because location
                    // is still off when there aren't any CDFs.
                    setTimeout(() => { ele.scrollIntoView(true); }, 1000);
                } else {
                    ele.scrollIntoView(true);
                }
                this.scrollToParam = undefined;
            }
        }
    }

    private translateText() {
        this.translateService.get([
            "PROMPT_DELETE_BODY", "CALENDAR_DATE_FORMAT", "PROMOTION_END_DATE_INVALID", "PROMOTION_NAME_VALIDATION_ERROR", "PROMOTION_NAME_UNIQUE_TO_CAMPAIGN", "PROMOTION_DELETE_ERROR", "PROMOTION_SAVE_ERROR",
            "PROMOTION_START_DATE_INVALID", "PLEASE_ENTER_PROMOTION_NAME", "PLEASE_ENTER_START_DATE", "MAX_LENGTH_ERROR", "PROMOTION_START_DATE_BEFORE_CAMPAIGN_IN_STORE_DATE", "START_DATE_WITH_LABEL", "END_DATE_WITH_LABEL"
        ]).subscribe((res: [string]) => {

            this.promptDeleteBody = res["PROMPT_DELETE_BODY"];
            this.promotionEndDateInvalid = res["PROMOTION_END_DATE_INVALID"];
            this.promotionNameValidationError = res["PROMOTION_NAME_VALIDATION_ERROR"];
            this.promotionNameUniqueToCampaign = res["PROMOTION_NAME_UNIQUE_TO_CAMPAIGN"];
            this.promotionDeleteError = res["PROMOTION_DELETE_ERROR"];
            this.promotionSaveError = res["PROMOTION_SAVE_ERROR"];
            this.promotionStartDateInvalid = res["PROMOTION_START_DATE_INVALID"];
            this.pleaseEnterPromoName = res["PLEASE_ENTER_PROMOTION_NAME"];
            this.pleaseEnterStartDate = res["PLEASE_ENTER_START_DATE"];
            this.textMaxLength = res["MAX_LENGTH_ERROR"];
            this.promotionStartBeforeInStoreFormat = res["PROMOTION_START_DATE_BEFORE_CAMPAIGN_IN_STORE_DATE"];

            this.calendarDateFormat = IpsString.Format(res["CALENDAR_DATE_FORMAT"], this.dateFormat);
            this.StartDateText = IpsString.Format(res["START_DATE_WITH_LABEL"], this.dateFormat);
            this.EndDateText = IpsString.Format(res["END_DATE_WITH_LABEL"], this.dateFormat);

        });
    }

    private createForm() {
        this.Id = new FormControl(this.promotionId);
        this.Name = new FormControl("", [Validators.required, Validators.maxLength(150)]);
        this.StartDate = new FormControl("", [this.ValidateStartDate(this.campaignInStore)]);
        this.EndDate = new FormControl("", [this.ValidateEndDate(this.StartDate)]);
        this.Notes = new FormControl("", [Validators.maxLength(10000)]);

        this.promoForm = new FormGroup({
            Id: this.Id,
            Name: this.Name,
            StartDate: this.StartDate,
            EndDate: this.EndDate,
            Notes: this.Notes,
            CampaignId: new FormControl(this.campaignId),
            BusinessIdentity: new FormControl(this.activeProfileService.businessIdentity),
            ClearAllMessages: new FormControl(false)
        });
    }

    public getErrorMessages(key: string) {
        let msgs = Object.assign({}, this.errorMessages);
        if (key) {
            switch (key.toLowerCase()) {
                case "name":
                    msgs["required"] = () => this.pleaseEnterPromoName;
                    break;
                case "startdate":
                    msgs["required"] = () => this.pleaseEnterStartDate;
                    break;
            }
        }
        return msgs;
    }

    private getCampaign(id: string | number): Promise<void> {
        return this.campaignService.get<CampaignData>(id).then((response: CampaignData) => {

            this.campaignName = response.Name;
            this.campaignFulfillmentQty = response.FulfillmentQuantity;
            this.campaignFulfillmentType = response.FulfillmentOperationType;
            this.campaignElementQty = response.ElementQuantity || 1;
            this.campaignInStore = new Date(response.InStoreDate);
            this.campaignStart = new Date(response.StartDate);
            this.campaignEnd = new Date(response.EndDate);

            this.startDateOptions.minDate = new Date(response.InStoreDate);
            this.startDateOptions.maxDate = new Date(response.EndDate);
            this.endDateOptions.minDate = new Date(response.StartDate);

            this.promoForm.patchValue({
                StartDate: this.campaignStart,
                EndDate: this.campaignEnd
            });
        });
    }

    private getPromo(promoId: string | number): Promise<void> {
        return Promise.all([
            this.promotionService.getPromotionHeader(promoId as string),
            //this.patternGroupService.getByPromotionId(id as number),
            this.promotionMessageService.GetByPromotionMessagesWithPlacements(promoId as number),
            this.getSignPlanLayoutSetting()
        ]).then((responseArray) => {
            let promoHeader = responseArray[0];
            let messagesWithPlacements: PromotionMessageWithPlacementsModel = responseArray[1];
            if (this.isCloneMode) {
                promoHeader.StartDate = this.campaignStart;
                promoHeader.EndDate = this.campaignEnd;
            }

            this.patternGroups = messagesWithPlacements.PatternGroups;
            if (this.patternGroups.length > 0) {

                if (this.patternGroups[0].PatternGroupType.toLocaleLowerCase() === "prioritymessage") {
                    let messagePat: PromotionMessagePatternModel[];
                    let plmtGroup: PromotionMessagePlacementGroupModel[] = [];
                    messagePat = [];
                    this.patternGroups.forEach(pgrp => {
                        let mspPat: PromotionMessagePatternModel = {
                            Markets: pgrp.Markets,
                            MarketGroupId: pgrp.MarketGroupId,
                            Id: pgrp.Id,
                            MessageName: pgrp.MessagePatterns[0].MessageName,
                            PromotionMessageId: pgrp.MessagePatterns[0].PromotionMessageId,
                            PromotionMessagePatternGroupId: pgrp.MessagePatterns[0].PromotionMessagePatternGroupId,
                            Ordinal: pgrp.MessagePatterns[0].Ordinal,
                            BusinessIdentity: pgrp.BusinessIdentity,
                            TempMessageId: pgrp.MessagePatterns[0].TempMessageId,
                            IsCdfEnabled: pgrp.MessagePatterns[0].IsCdfEnabled
                        };
                        messagePat.push(mspPat);
                        if (pgrp.PlacementGroups) {
                            pgrp.PlacementGroups.forEach(pg => {
                                plmtGroup.push(pg);
                            });
                        }
                    });
                    let patternGroupData = this.patternGroups[0];
                    this.patternGroups = [];
                    this.patternGroups.push(patternGroupData);
                    this.patternGroups[0].MessagePatterns = messagePat;
                    this.patternGroups[0].PlacementGroups = plmtGroup;
                    this.selectedMessageType = "priorityFill";
                }
                else if (this.patternGroups.length > 1) {
                    this.useMessagePattern = false;
                    this.selectedMessageType = "ordinaryMessage";
                } else if (this.patternGroups[0].PatternGroupType.toLocaleLowerCase() === "multimessage") {
                    this.useMessagePattern = true;
                    this.selectedMessageType = "patternMessage";
                }

                this.patternGroups.forEach(pg => {
                    if (!pg.Name) {
                        pg.Name = `pgs${pg.Id}`;
                    }
                });

                //Check if promo is using MessagePatterns and set Patterns on promo if so.
                this.useMessagePattern = messagesWithPlacements.PatternGroups[0].PatternGroupType !== "SingleMessage" && messagesWithPlacements.PatternGroups[0].PatternGroupType !== "PriorityMessage";

                messagesWithPlacements.PromotionMessageAndPlacements.forEach((calcResultModel) => {
                    const tempId = this.tempMessageId++;
                    calcResultModel.PromotionMessage.TempId = tempId;
                    if (calcResultModel.PlacementElements) {
                        calcResultModel.PlacementElements.forEach(item => item.TempMessageId = tempId);
                    }
                    if (calcResultModel.MessagePlacements) {
                        calcResultModel.MessagePlacements.forEach(item => item.MessagePlacement.TempMessageId = tempId);
                    }


                    // Loop through patterns and update tempId
                    let cdfEnabled = true;
                    messagesWithPlacements.PatternGroups.forEach(patternGroup => patternGroup.MessagePatterns.forEach(msgPattern => {
                        if (msgPattern.PromotionMessageId === calcResultModel.PromotionMessage.Id) {
                            msgPattern.TempMessageId = tempId;
                            msgPattern.IsCdfEnabled = cdfEnabled;
                            if (cdfEnabled) {
                                cdfEnabled = false;
                            }
                        }
                    }));
                });
                this.selectedStatus = promoHeader.Status;
                if (this.selectedMessageType !== "priorityFill") {
                    this.patternGroups = messagesWithPlacements.PatternGroups;
                }
                this.promotionMessageAndPlacements = messagesWithPlacements.PromotionMessageAndPlacements;
            }

            this.promoForm.patchValue({
                Id: this.promotionId, // Use this.promotionId because we always want to use this. Passed in id in clone mode is id we are loading, not saving.
                Name: promoHeader.Name,
                StartDate: new Date(promoHeader.StartDate),
                EndDate: promoHeader.EndDate ? new Date(promoHeader.EndDate) : null,
                Notes: promoHeader.Notes
            });

            this.selectedStatus = promoHeader.Status;
            this.originalPromoName = promoHeader.Name;
            this.originalPromoStart = new Date(promoHeader.StartDate);

            //show warning if promotion start date before campaign in store date
            if (this.campaignInStore > this.promoForm.value.StartDate) {
                this.promotionStartBeforeInStore = IpsString.Format(this.promotionStartBeforeInStoreFormat, this.datePipe.transform(this.campaignInStore, "mediumDate"));
                this.showStartDateWarning = true;
            } else {
                this.showStartDateWarning = false;
            }
            this.loaded = true;

            this.showMessageEditComponent = messagesWithPlacements.PromotionMessageAndPlacements.length > 0;

            if (this.isCloneMode) {
                //Set focus on the name field
                setTimeout(() => {
                    let element = $("#Name");
                    element[0].focus();
                    element.setValue(element.value);
                }, 50);
            }
        });
    }

    public addPromotion() {
        this.$state.go("main.campaign.promotion.edit", { campaignId: this.campaignId, id: 0 }, { reload: true });
    }

    private validatePromoName() {
        if (this.promoForm.value.Id > 0) {
            if (this.promoForm.value.Name !== this.originalPromoName) {
                return this.promotionService.checkPromotionName(this.promoForm.value);
            } else {
                return new Promise((resolve, reject) => { resolve(true); });
            }
        }
        return this.promotionService.checkPromotionName(this.promoForm.value);
    }

    /**
    * Property used for start date validation. It must be:
    * Parsable (handled automatically)
    * On or after in-store date
    */
    public ValidateStartDate(inStore: Date): ValidatorFn {
        return (StartDate: AbstractControl): ValidationErrors | null => {
            if (StartDate !== undefined) {
                let val = StartDate.value;
                // only revalidate if the date has changed or the promotion is new, otherwise there's a warning letting them know there's a problem
                if (this.originalPromoStart !== StartDate.value) {
                    if (val < this.startDateOptions.minDate || val > this.startDateOptions.maxDate) {
                        return {
                            promotionStartDateInvalid: {
                                valid: false
                            }
                        };
                    }
                }
            } else {
                return {
                    calendarDateFormat: {
                        valid: false
                    }
                };
            }

            //Check for required - disabled for manual runs
            if (!this.isManualStartDate) {
                if (!StartDate.value) {
                    return {
                        required: {
                            valid: false
                        }
                    };
                }
            }
            this.isManualStartDate = false;

            if (this.showStartDateWarning && (this.promoForm.controls["StartDate"].dirty || this.promoForm.controls["StartDate"].touched)) {
                if (this.promoForm.value.Id <= 0 || this.originalPromoStart !== this.promoForm.value.StartDate) {
                    this.showStartDateWarning = false;
                }
            }

            return null;
        };
    }

    /**
    * Property used for end-store date validation. It must be:
    * Parsable (handled automatically)
    * On or after start date
    */
    public ValidateEndDate(StartDate: AbstractControl): ValidatorFn {
        return (EndDate: AbstractControl): ValidationErrors | null => {
            if (EndDate !== undefined) {
                // A null end date value means the user cleared it, don't validate unless it is non-null
                if (EndDate.value && StartDate !== undefined) {
                    if (EndDate.value < this.endDateOptions.minDate) {
                        return {
                            promotionEndDateInvalid: {
                                valid: false
                            }
                        };
                    }
                }
            } else {
                // An undefined value means the control couldn't parse the text, must be invalid format.
                return {
                    calendarDateFormat: {
                        valid: false
                    }
                };
            }

            return null;
        };
    }

    // Used to overcome limitations with required and our custom date validation
    // blurStartDate()
    // This will call validate on blur just like require does
    public blurStartDate(test: any) {
        this.StartDate.updateValueAndValidity();
    }

    public changeStartDate(value: Date) {
        this.endDateOptions.minDate = value;

        //force validation call
        this.StartDate.setValue(value);
        this.EndDate.updateValueAndValidity();
        if (this.EndDate.errors) {
            this.EndDate.markAsDirty();
        }

        this.StartDate.markAsDirty();
    }

    public toggleUseMessagePattern(isPattern: boolean) {
        if (isPattern) {
            this.selectedMessageType = "patternMessage";
        }
        else {
            this.selectedMessageType = "ordinaryMessage";
        }
        if (isPattern !== this.useMessagePattern) {
            this.ipsMessage.confirm({
                title: "WARNING",
                body: "PATTERN_TYPE_CHANGE_WARNING",
                ok: "YES_RESET_MY_INFO",
                cancel: "NO_KEEP_MY_INFO"
            }).then(() => {
                if (isPattern) {
                    this.selectedMessageType = "patternMessage";
                }
                else {
                    this.selectedMessageType = "ordinaryMessage";
                }
                //User chose to proceed with pattern change
                if (this.placementComponent) {
                    this.placementComponent.clearPlacements();
                }
                if (this.placementElementComponent) {
                    this.placementElementComponent.clearPlacements();
                }
                this.useMessagePattern = !this.useMessagePattern;
            })
                .catch(() => {
                    // rejection
                });
        }
        else {
            if (isPattern) {
                this.selectedMessageType = "patternMessage";
            }
            else {
                this.selectedMessageType = "ordinaryMessage";
            }
        }
    }

    public placeFixture(): void {
        this.showPlacementSelectionModal("fixture");
    }

    public placeElement(): void {
        this.showPlacementSelectionModal("element");
    }


    private showPlacementSelectionModal(placementType: PlacementType) {
        let param = {};
        if (placementType === "fixture") {
            param = { Placements: this.placementComponent.getCurrentPlacements() };
        } else if (placementType === "element") {
            param = { PlacementElements: this.placementElementComponent ? this.placementElementComponent.GetCurrentPlacementElements() : [] };
        }


        return this.ipsModal.displayTemplateScrollable(PlacementSearchModalComponent,
            {
                resolve: param
            }, { backdrop: "static", centered: true })
            .then((response: PlacementModalSearchResultModel) => {
                if (placementType === "fixture") {
                    this.placementComponent.onHolderPlacementsAdded(response.FixtureGroups);
                } else if (placementType === "element") {
                    this.placementElementComponent.onPlacementElementsAdded(response.Elements);
                    this.promoForm.markAsDirty();
                }
            }, (rejectReason) => {
                // Do nothing, this is here to prevent console error.
            });
    }

    public messageDeletedEventHandler(messageModel: PromotionMessagePatternModel) {
        if (this.placementElementComponent) {
            this.placementElementComponent.onMainMessageDeleted(messageModel);
        }
        this.placementComponent.onMainMessageDeleted(messageModel);

        let allPatterngGroupDeleted = false;
        if (this.useMessagePattern) {
            allPatterngGroupDeleted = this.messagePatternEditComponent.PatternGroups.length === 0 || !this.messagePatternEditComponent.PatternGroups.controls.some((ptnGrp: FormGroup) => this.messagePatternEditComponent.MessagePatterns(ptnGrp).length > 0);
        } else if (this.selectedMessageType === "priorityFill") {
            allPatterngGroupDeleted = this.messagePrirorityFillEditComponent.PatternGroups.length === 0;
        } else {
            allPatterngGroupDeleted = this.messageEditComponent.PatternGroups.length === 0;
        }

        if (allPatterngGroupDeleted) {
            this.showMessageEditComponent = false;
            this.createMessaging = true;
            this.promoForm.removeControl("messageForm");

            //Clear all placements and messages
            //Special case where user will delete all messages and then click create message button without saving
            this.promoForm.controls["ClearAllMessages"].setValue(true);
        }
    }

    public messageAddedEventHandler(messageModel: PromotionMessagePatternModel) {
        if (this.placementElementComponent) {
            this.placementElementComponent.onMainMessageAdded(messageModel);
        }
        this.placementComponent.onMainMessageAdded(messageModel);
    }

    public messageNameChangedEventHandler(messageInfo: MessageNameChangeModel) {
        if (this.placementElementComponent) {
            this.placementElementComponent.onMessageNameChanged(messageInfo);
        }
        this.placementComponent.onMessageNameChanged(messageInfo);
    }

    public savePromoPrompt(redirect: boolean) {
        validateAllFormFieldsWithDialog(this.promoForm, this.ipsMessage, () => this.savePromoPrompt2(redirect));
    }

    private savePromoPrompt2(redirect: boolean) {
        let savePromise = this.validatePromoName().then((response: boolean) => {
            if (response === true) {
                return this.ipsMessage.waitForWork({
                    body: "SAVING",
                    workFunction: () => this.savePromo(),
                    progressMessage: "SAVING",
                    successMessage: this.isCloneMode ? "SUCCESSFULLY_CLONED" : "SUCCESS"
                }).then((result: any) => {
                    //this.disableCreateMessage = false;
                    if (result && redirect) {
                        this.$state.go("main.campaign.view", { id: this.campaignId, promotionId: this.promotionId });
                    } else {
                        this.$state.go("main.campaign.promotion.edit", { campaignId: this.campaignId, id: this.Id.value, clonePromotionId: undefined }, { reload: true });
                    }
                });

            } else {
                this.ipsMessage.error(this.promotionNameUniqueToCampaign);
            }
        }).catch(reason => { });
    }

    public buttonSelectionChanged(messageType: string) {
        this.selectedMessageType = messageType;
       if (messageType === "patternMessage") {
            this.toggleUseMessagePattern(true);
       }
       else if (messageType === "priorityFill") {
               //User chose to proceed with pattern change
               if (this.placementComponent) {
                   this.placementComponent.clearPlacements();
               }
               if (this.placementElementComponent) {
                   this.placementElementComponent.clearPlacements();
               }
            this.useMessagePattern = false;
       }
       else {
        this.useMessagePattern = false;
       }
    }

    public marketChangedEventHandler(TempPatternGroupId: number) {
        let messagePriorityId: number;

        if (this.selectedMessageType === "priorityFill") {
            messagePriorityId = TempPatternGroupId;
            TempPatternGroupId = 1;
        }
        let patternGroupCtrl = this.promotionHelperService.getPatternGroup(this.promoForm, TempPatternGroupId);



        if (patternGroupCtrl.controls.Markets.valid || this.selectedMessageType === "priorityFill") {
            let promo: PromotionHeaderModel = this.promoForm.value;
            let patternGroup: PromotionMessagePatternGroupModel = patternGroupCtrl.value;

            let markets = patternGroup.Markets;

            if (this.selectedMessageType === "priorityFill") {
                patternGroup.MessagePatterns.forEach(objMessage => {
                    if (objMessage.TempMessageId === messagePriorityId) {
                        markets = objMessage.Markets;
                    }
                });
            }

            //patternGroupInfo
            let patternGroupInfo: PromotionMessagePatternGroupModel = {
                Id: patternGroup.Id,
                PromotionId: promo.Id,
                Name: patternGroup.Name,
                MessagePatterns: patternGroup.MessagePatterns,
                MarketGroupId: patternGroup.MarketGroupId,
                Markets: markets,
                PatternGroupType: patternGroup.PatternGroupType,
                BusinessIdentity: patternGroup.BusinessIdentity,
                StartDate: promo.StartDate, // PromoStartDate
                EndDate: promo.EndDate,  // PromoEndDate
            };
            //placementElements
            let placementElements;
            let tempMsgId = patternGroup.MessagePatterns[0].TempMessageId;
            placementElements = this.placementElementComponent ? this.placementElementComponent.getElementPlacementByMessage(tempMsgId) : undefined;

            this.placementComponent.UpdatePostionLocationCountsForMarketCalc().then(() => {
                // message placements / placement groups
                const placementInfo = this.placementComponent.GetPlacementModelForMarketCalc(patternGroup);

                //Remove un-needed spaces
                if (placementElements) {
                    placementElements = Object.assign([], placementElements);
                    placementElements.forEach(item => {
                        delete item.Spaces;
                    });
                }

                //re-calculate
                let balanceCalcModel = <MessagePlacementCalculationModel>{
                    PatternGroupInfo: patternGroupInfo,
                    MessagePlacements: placementInfo.MessagePlacements,
                    PlacementElements: placementElements,
                    ReturnLocationBalance: false,
                    PlacementGroups: placementInfo.PlacementGroups,
                    DefaultFulfillmentOperator: this.campaignFulfillmentType,
                    DefaultFulfillmentQuantity: this.campaignFulfillmentQty
                };
                // Call server to get new totals
                if (this.selectedMessageType === "priorityFill") {
                    balanceCalcModel.PatternGroupInfoForPriorityFill = [];
                    balanceCalcModel.PatternGroupInfoForPriorityFill.push(balanceCalcModel.PatternGroupInfo);
                    this.promotionMessageService.calculatePriorityFillLocationBalance(balanceCalcModel).then((response: MessagePlacementCalculationResultModel) => {

                        if (response !== null) {
                            //update message location count
                            //let markets = response.MarketBalance;

                            if (this.selectedMessageType === "priorityFill") {
                                let messageFormArray = patternGroupCtrl.controls.MessagePatterns as FormArray;
                                let cuurentControl = messageFormArray.controls[messagePriorityId - 1] as FormGroup;
                                let marketLimit = cuurentControl.controls.Markets as FormArray;

                                this.promotionHelperService.updateMarketCtrlAfterCalculationPriorityFill(marketLimit, response.MarketBalance);
                            }
                            else {
                                this.promotionHelperService.updateMarketCtrlAfterCalculation(patternGroupCtrl, response.MarketBalance);
                            }
                            //update placement elements location count
                            if (response.PlacementElements) {
                                this.placementElementComponent.UpdateElementAfterCalculation(response.PlacementElements, tempMsgId);
                            }

                            if (response.MessagePlacements) {
                                this.placementComponent.UpdatePlacementsAfterCalculation(response.MessagePlacements, patternGroup);
                            }
                        }
                    });
                }
                else {
                    this.promotionMessageService.calculateLocationBalance(balanceCalcModel).then((response: MessagePlacementCalculationResultModel) => {

                        if (response !== null) {
                            //update message location count
                            //let markets = response.MarketBalance;

                            if (this.selectedMessageType === "priorityFill") {
                                let messageFormArray = patternGroupCtrl.controls.MessagePatterns as FormArray;
                                let cuurentControl = messageFormArray.controls[messagePriorityId - 1] as FormGroup;
                                let marketLimit = cuurentControl.controls.Markets as FormArray;

                                this.promotionHelperService.updateMarketCtrlAfterCalculationPriorityFill(marketLimit, response.MarketBalance);
                            }
                            else {
                                this.promotionHelperService.updateMarketCtrlAfterCalculation(patternGroupCtrl, response.MarketBalance);
                            }
                            //update placement elements location count
                            if (response.PlacementElements) {
                                this.placementElementComponent.UpdateElementAfterCalculation(response.PlacementElements, tempMsgId);
                            }

                            if (response.MessagePlacements) {
                                this.placementComponent.UpdatePlacementsAfterCalculation(response.MessagePlacements, patternGroup);
                            }
                        }
                    });
                }
            });
        }

    }

    private savePromo(): Promise<any> {
        let id = this.Id.value;
        //Add promo header data
        let promoValues = this.promoForm.value as PromotionModel;
        let promoSaveModel: PromotionSaveModel = {
            Id: id,
            CampaignId: this.campaignId,
            Name: promoValues.Name,
            StartDate: promoValues.StartDate,
            EndDate: promoValues.EndDate,
            Notes: promoValues.Notes,
            Status: this.selectedStatus,
            PatternGroups: null,
            BusinessIdentity: promoValues.BusinessIdentity
        };

        //Grab message & market data
        if (this.useMessagePattern) {
            if (this.messagePatternEditComponent) {
                this.messagePatternEditComponent.prepareSaveModel(promoSaveModel);
            }
        } else if (this.selectedMessageType === "priorityFill") {
            if (this.messagePrirorityFillEditComponent) {
                this.messagePrirorityFillEditComponent.prepareSaveModel(promoSaveModel);
            }
        } else {
            if (this.messageEditComponent) {
                this.messageEditComponent.prepareSaveModel(promoSaveModel);
            }
        }

        //Add placements
        if (this.placementComponent) {
            this.placementComponent.prepareSaveModel(promoSaveModel);
        }

        if (this.placementElementComponent) {
            this.placementElementComponent.prepareSaveModel(promoSaveModel);
        }

        if (this.selectedMessageType === "priorityFill") {
            promoSaveModel.PatternGroups.forEach(patn => {
                patn.MessagePatterns.forEach(msgPtn => {
                    msgPtn.MessagePlacements.forEach(mesgPlt => {
                        if (!mesgPlt.BusinessIdentity) {
                            mesgPlt.BusinessIdentity = promoSaveModel.BusinessIdentity;
                        }
                    });
                });
            });
        }
        //Save
        if (id) {
            let promises: Promise<any>[] = [];
            let promoSavePromise = this.promotionService.put<PromotionSaveModel>(promoSaveModel).then((result) => {
                return this.saveCdfData(result);
            }).catch(reason => {
                this.logger.error("Failed to save promotion. SaveModel: " + JSON.stringify(promoSaveModel), undefined);
                return Promise.reject({ Message: this.promotionSaveError });
            });
            promises.push(promoSavePromise);

            // Save promo cdf data
            promises.push(this.customDataFieldContainerComponent.save());
            return Promise.all(promises);

        } else {
            return this.promotionService.post<PromotionSaveModel>(promoSaveModel, undefined, undefined).then(result => {
                this.promotionId = result.Id;
                this.promoForm.patchValue({ Id: result.Id });

                return this.saveCdfData(result, true);
            }).catch(() => {
                this.logger.error("Failed to save promotion. SaveModel: " + JSON.stringify(promoSaveModel), undefined);
                return Promise.reject({ Message: this.promotionSaveError });
            });
        }
    }

    private saveCdfData(result: PromotionSaveModel, savePromoCdf = false): Promise<any> {
        let promises: Promise<any>[] = [];
        if (savePromoCdf) {
            promises.push(this.customDataFieldContainerComponent.save(result.Id));
        }
        if (this.messageEditComponent) {
            promises = promises.concat(this.messageEditComponent.saveCustomData(result));
        }
        if (this.messagePatternEditComponent) {
            promises = promises.concat(this.messagePatternEditComponent.saveCustomData(result));
        }
        if (this.placementElementComponent) {
            promises = promises.concat(this.placementElementComponent.saveCustomData(result));
        }
        if (this.placementComponent) {
            promises = promises.concat(this.placementComponent.saveCustomData(result));
        }
        if (this.messagePrirorityFillEditComponent) {
            promises = promises.concat(this.messagePrirorityFillEditComponent.saveCustomData(result));
        }

        if (promises.length > 0) {
            return Promise.all(promises).then((response: any) => {
                this.promoForm.markAsPristine();
            });
        }
        this.promoForm.markAsPristine();
        return Promise.resolve(true);
    }

    public messageOrdinalChangedEventHandler() {
        this.placementComponent.onPatternMessageOrdinalChanged();
    }

    public createBlankMessage(): void {
        //This is called by "Create Messaging" button.
        //Once showMessageEditComponent is set to true the messageEdit component is initialized and is responsible for adding a blank message if none exist on the promotion.
        this.showMessageEditComponent = true;
        this.createMessaging = true;

        //after loading message component the status of promoForm changed
        //calling detectChanges method of update the form expressions
        this.changeDetector.detectChanges();
    }

    public deletePromoPrompt() {
        let translated = this.translateService.instant("PROMPT_DELETE_BODY");
        translated = IpsString.Format(translated, this.promoForm.value.Name);
        return this.ipsMessage.confirmDelete({ body: translated, workFunction: () => this.deletePromo(), progressMessage: "DELETING" })
            .then((result: any) => {
                if (result) {
                    this.$state.go("main.campaign.search");
                }
            })
            .catch(() => {
                // rejection
            });
    }

    private deletePromo() {
        return this.promotionService.delete(this.promoForm.value.Id).catch((err: Error) => {
            this.ipsMessage.error(this.translateService.instant("PROMOTION_DELETE_ERROR"));
        });
    }

    public toggleReorderMessages(reorderMessages: boolean) {
        //Verify if user has validation errors
        if ((this.promoForm.controls.messageForm.get("PatternGroups") as FormArray).controls[0].get("MessagePatterns").invalid) {
            this.ipsMessage.messageBox({
                title: "WARNING",
                body: "REORDER_MESSAGES_FIELDS_REQUIRED"
            });
            return;
        }
        if (this.selectedMessageType === "priorityFill") {
            this.messagePrirorityFillEditComponent.toggleReorderMessages(reorderMessages);
        }
        else {
            this.messagePatternEditComponent.toggleReorderMessages(reorderMessages);
        }
        this.reorderMessages = reorderMessages;
    }

    public change(event: any) {
        this.selectedStatus = event;
    }
}
